import { Body, Dialog, Stack } from "design-system";
import { option } from "fp-ts";
import { LocaleKey, useFormatMessage } from "../../intl";

type Props = {
  onDismiss: () => unknown;
};

export function SmartKeyActivationDialog(props: Props) {
  const formatMessage = useFormatMessage();

  const paragraph = (title: LocaleKey, text: LocaleKey) => (
    <>
      <Body size="medium" weight="medium">
        {formatMessage(title)}
      </Body>
      <Body size="medium" weight="regular">
        {formatMessage(text)}
      </Body>
    </>
  );

  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage("SecurityPin.Modal.title")}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <Stack column units={4}>
        {paragraph("SecurityPin.Modal.title1", "SecurityPin.Modal.text1")}
        {paragraph("SecurityPin.Modal.title2", "SecurityPin.Modal.text2")}
        {paragraph("SecurityPin.Modal.title3", "SecurityPin.Modal.text3")}
        {paragraph("SecurityPin.Modal.title4", "SecurityPin.Modal.text4")}
        {paragraph("SecurityPin.Modal.title5", "SecurityPin.Modal.text5")}
      </Stack>
    </Dialog>
  );
}
