import * as t from "io-ts";
import { eq } from "fp-ts";
import { apiCall } from "../../APICall";
import { Eq } from "fp-ts/Eq";
import { CompanySuggestion } from "./domain";
import { RemoteData } from "../../RemoteData";
import { DropdownOption, SuggestionsStatus } from "design-system";
import { pipe, constant } from "fp-ts/function";
import * as remoteData from "../../RemoteData";

export const CompanySuggestionsInput = t.type(
  {
    text: t.string,
  },
  "CompanySuggestionsInput"
);

export interface CompanySuggestionsInput
  extends t.TypeOf<typeof CompanySuggestionsInput> {}

export const eqCompanySuggestionsInput: Eq<CompanySuggestionsInput> = eq.getStructEq(
  {
    text: eq.eqString,
  }
);

export const CompanySuggestionsOutput = t.array(
  CompanySuggestion,
  "CompanySuggestionsOutput"
);
export interface CompanySuggestionsOutput
  extends t.TypeOf<typeof CompanySuggestionsOutput> {}

export const companySuggestion = apiCall({
  inputEq: eqCompanySuggestionsInput,
  path: ["utilities", "company", "search"],
  inputCodec: CompanySuggestionsInput,
  outputCodec: CompanySuggestionsOutput,
});

export function autocompleteInputStatusFromRemoteData<I, O>(
  query: RemoteData<unknown, I[]>,
  suggestionsMap: (suggestion: I) => DropdownOption<O>
): SuggestionsStatus<O> {
  return pipe(
    query,
    remoteData.fold(
      constant({
        status: "loading",
      } as SuggestionsStatus<O>),
      constant({ status: "ready", suggestions: [] }),
      (suggestions, isLoading) =>
        isLoading
          ? {
              status: "loading",
            }
          : {
              status: "ready",
              suggestions: suggestions.map(suggestionsMap),
            }
    )
  );
}
