import { Zipper } from "fp-ts-contrib/lib/Zipper";
import * as zipper from "fp-ts-contrib/lib/Zipper";
import { ordering } from "fp-ts";
import { Ordering } from "fp-ts/Ordering";
import { pipe } from "fp-ts/function";

export function mapWithIndexAndOrdering<A, B>(
  f: (ordering: Ordering, index: number, a: A) => B
): (fa: Zipper<A>) => Zipper<B> {
  return fa => {
    const l = fa.lefts.length;
    return pipe(
      fa,
      zipper.mapWithIndex((i, a) => f(ordering.sign(i - l), i, a))
    );
  };
}
