import * as t from "io-ts";
import { LocalizedString } from "design-system";

export const CompanySuggestion = t.type(
  {
    name: LocalizedString,
    id: LocalizedString,
  },
  "CompanySuggestion"
);
export type CompanySuggestion = t.TypeOf<typeof CompanySuggestion>;
