import { ComponentType, ComponentProps, useMemo, useRef } from "react";
import { useCommand } from "../../useAPI";
import { CompanyField } from "./CompanyField";
import { CompanySuggestion } from "./domain";
import * as companyApi from "./api";
import {
  Validator,
  validators,
  LocalizedString,
  fieldIssues,
} from "design-system";
import { constant, constFalse, pipe } from "fp-ts/function";
import { array, taskEither } from "fp-ts";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

type CompanyFieldProps = ComponentProps<typeof CompanyField>;
type UseCompanyFieldProps = Omit<CompanyFieldProps, "for" | "queryString"> & {
  onValidationChange?: (error: boolean) => unknown;
  onCompanyIcoValidationChange?: (error: boolean) => unknown;
};

type UseCompanyFieldsReturn = {
  Name: ComponentType<UseCompanyFieldProps>;
  ICO: ComponentType<UseCompanyFieldProps>;
};

export function useCompanyFields(): UseCompanyFieldsReturn {
  const activeQueryTerms = useRef({
    name: "",
    ico: "",
  });

  return useMemo(
    () => ({
      Name: (props: UseCompanyFieldProps) => (
        <CompanyField
          {...props}
          onValidationChange={props.onValidationChange}
          for="Name"
          queryString={activeQueryTerms.current.name}
          onChange={value => {
            activeQueryTerms.current = {
              ...activeQueryTerms.current,
              name: value,
            };
            props.onChange(value);
          }}
        />
      ),
      ICO: (props: UseCompanyFieldProps) => (
        <CompanyField
          {...props}
          onValidationChange={props.onCompanyIcoValidationChange}
          for="ICO"
          queryString={activeQueryTerms.current.ico}
          onChange={value => {
            activeQueryTerms.current = {
              ...activeQueryTerms.current,
              ico: value,
            };
            props.onChange(value);
          }}
        />
      ),
    }),
    []
  );
}

export const removeDiacritics = (text: string) =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const useCompanyNameAndIcoValidators = () => {
  const fetchCompanySuggestions = useCommand(companyApi.companySuggestion, {
    skipTracking: true,
  });

  const validator = (key: keyof CompanySuggestion) => <S extends string>(
    errorMessage: LocalizedString
  ): Validator<S | NonEmptyString> =>
    validators.validateIfNonEmpty(text =>
      pipe(
        fetchCompanySuggestions({
          text: pipe(text.toLowerCase(), removeDiacritics),
        }),
        taskEither.filterOrElseW(
          suggestions =>
            pipe(
              suggestions,
              array.some(suggestion => suggestion[key] === text.toString())
            ),
          constFalse
        ),
        taskEither.bimap(
          constant(fieldIssues.errors([errorMessage])),
          constant(text)
        )
      )
    );

  return { validName: validator("name"), validIco: validator("id") };
};
