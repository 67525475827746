import { option } from "fp-ts";
import {
  FileContent,
  Box,
  Dialog,
  Loader,
  LocalizedString,
  convertToBlob,
} from "design-system";
import * as classes from "./FileImageDialog.treat";
import cx from "classnames";
import { TaskEither } from "fp-ts/TaskEither";
import { useRemoteData } from "../../useRemoteData";
import * as remoteData from "../../RemoteData";

import { constNull, pipe } from "fp-ts/function";

type Props = {
  id?: string;
  /**
   * Title of the Modal
   */
  title: LocalizedString;
  /**
   * Action to be performed when the modal is dismissed
   */
  onDismiss: () => unknown;

  /**
   * Name of the file (without extension) that gets downloaded
   */
  fileName: string;

  /** How to retrieve the file when the modal is opened */
  file: TaskEither<unknown, FileContent>;
};

export function FileImageDialog(props: Props) {
  const file = useRemoteData(props.file);

  return (
    <Dialog
      id={props.id}
      variant="left"
      size="medium"
      title={props.title}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      {pipe(
        file,
        remoteData.fold(
          () => <Loader />,
          constNull,
          file => {
            const imageUrl = `${URL.createObjectURL(
              convertToBlob(file, "Image")
            )}`;
            return (
              <Box grow shrink hAlignContent="center">
                <img
                  alt={props.title}
                  title={props.title}
                  src={imageUrl}
                  className={cx(classes.imageStyle)}
                />
              </Box>
            );
          }
        )
      )}
    </Dialog>
  );
}
