import { createContext, useContext } from "react";
import { constVoid } from "fp-ts/function";

export interface BranchExperienceContext {
  branchExperienceFeaturesActive: boolean;
  isPotentialClientLoginCompleted: boolean;
  openChildWindow: () => void;
  postMessage: (message: unknown) => void;
  setTempDisabled: (
    tempDisabled: boolean | ((prev: boolean) => boolean)
  ) => void;
}

export const BranchExperienceContext = createContext<BranchExperienceContext>({
  branchExperienceFeaturesActive: false,
  isPotentialClientLoginCompleted: false,
  openChildWindow: constVoid,
  postMessage: constVoid,
  setTempDisabled: constVoid,
});

export function useBranchExperienceContext(): BranchExperienceContext {
  return useContext(BranchExperienceContext);
}
