import { createContext, useContext } from "react";
import { useIdleCountdown as useIdleCountdownHook } from "./useIdleCountdown";
import { Children } from "design-system";

type Props = {
  minutesBeforeIdle: number;
  minutesWarningDuration: number;
  onIdle: () => void;
  children: Children;
  renderWarning: (
    dismissIdle: () => void,
    remainingTime: number
  ) => JSX.Element;
};

type IdleStatusProviderProps = {
  countdown: number;
  showWarning: boolean;
  dismissWarning: () => void;
};

const IdleStatusProvider = createContext<IdleStatusProviderProps>({
  countdown: 0,
  showWarning: false,
  dismissWarning: () => {},
});

export function IdleGuard(props: Props) {
  const minutesToMs = 60e3;
  const { countdown, showWarning, dismissWarning } = useIdleCountdownHook(
    props.minutesBeforeIdle * minutesToMs,
    props.minutesWarningDuration * minutesToMs,
    props.onIdle
  );

  return (
    <IdleStatusProvider.Provider
      value={{ countdown, showWarning, dismissWarning }}
    >
      {props.children}
      {showWarning && props.renderWarning(dismissWarning, countdown)}
    </IdleStatusProvider.Provider>
  );
}

export function useIdleCountdown() {
  return useContext(IdleStatusProvider);
}
