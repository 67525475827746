import { useState, useRef } from "react";
import {
  menuWrapper,
  menu,
  option as optionClass,
  heading as headingClass,
  openMenuIcon,
} from "./MenuDropdown.treat";
import {
  AngleUpIcon,
  AngleDownIcon,
  Box,
  Space,
  Body,
  LocalizedString,
  Children,
} from "design-system";
import { useClickAway } from "react-use";
import * as classes from "./Dropdown.treat";
import cx from "classnames";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { CommonProps } from "../MainContent/CommonProps";

export type MenuOption = {
  label: LocalizedString;
  action: () => unknown;
  icon: (props: { size: "small" }) => Children;
};

type Props = CommonProps & {
  options: [MenuOption, ...MenuOption[]];
  heading: Option<LocalizedString>;
};

export function MenuDropdown(props: Props) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  useClickAway(menuRef, () => setOpen(false));

  return (
    <>
      <Box onClick={() => setOpen(!open)}>
        {open ? (
          <AngleUpIcon size="default" className={openMenuIcon} />
        ) : (
          <AngleDownIcon size="default" className={openMenuIcon} />
        )}
      </Box>
      <Box className={menuWrapper} column>
        {open && (
          <Box className={cx(menu, classes.menu)} ref={menuRef} column>
            {pipe(
              props.heading,
              option.fold(
                () => null,
                heading => (
                  <Box
                    className={cx(optionClass, headingClass)}
                    vAlignContent="center"
                  >
                    <Body weight="regular" size="x-small">
                      {heading}
                    </Body>
                  </Box>
                )
              )
            )}
            {props.options.map(option => (
              <Box
                key={option.label}
                className={cx(
                  optionClass,
                  classes.option,
                  classes.optionSelected.no
                )}
                onClick={() => {
                  option.action();
                  setOpen(false);
                }}
                vAlignContent="center"
              >
                {option.icon({ size: "small" })}
                <Space units={2} />
                {option.label}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
}
