import { UploadDocumentFlowType } from "../../../globalDomain";

export function foldVariantReduced(
  productType: UploadDocumentFlowType,
  whenLoans: () => unknown,
  whenMortgage: () => unknown,
  whenPersonalProfile: () => unknown
) {
  switch (productType) {
    case "MORTGAGE":
      return whenMortgage();
    case "PersonalProfile":
      return whenPersonalProfile();
    case "TL":
    case "CL":
    case "SL":
    case "RL":
    case "RPL":
      return whenLoans();
  }
}

export function getTranslationProductPrefix(
  productType: UploadDocumentFlowType
) {
  return foldVariantReduced(
    productType,
    () => "StandardLoan",
    () => "Mortgage",
    () => "Mortgage"
  );
}
