import { FeedbackDialog } from "design-system";
import { useFormatMessage } from "../../intl";

type Props = {
  onClose: () => void;
};

export function ApplicationLockedPopup(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <FeedbackDialog
      type="warning"
      title={formatMessage("Application.locked.warning.title")}
      cta={{
        label: formatMessage("Application.locked.warning.close"),
        action: props.onClose,
      }}
    />
  );
}
