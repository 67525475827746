import { useCallback, useMemo, useState } from "react";
import {
  defaultBrowserLocale,
  defaultLocaleFromTenant,
  importMessages,
  IntlProvider,
  localeFromUrl,
  LocaleMessages,
} from "./intl";
import { either, option, taskEither } from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { pipe } from "fp-ts/function";
import { AppContext } from "./AppContext";
import { useRemoteData } from "./useRemoteData";
import * as remoteData from "./RemoteData";
import { useAPIEndpoint } from "./useAPIEndpoint";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import {
  APIParameters,
  Channel,
  SupportedLocales,
  Tenant,
} from "./globalDomain";
import { importConfigurations, RemoteConfig } from "./ConfigBlocks";
import {
  Dialog,
  ErrorBanner,
  LoadingStatusProvider,
  LocalizedString,
  Space,
  unsafeLocalizedString,
  WarningIcon,
} from "design-system";
import { DocumentTitle } from "./DocumentTitle";
import { BasicFullScreenLoader } from "./Common/BasicFullScreenLoader";
import { PortalStatusProvider } from "./PortalStatusContext";
import { AuthenticationContextProvider } from "./AuthenticationContext";
import { sequenceS } from "fp-ts/Apply";
import { getCurrentBuildVersion } from "./VersionCheck/Utils";

type Props = {
  channel: Channel;
  children: JSX.Element;
  locale?: SupportedLocales;
  forceLocalLang?: boolean;
};

function importTranslations(
  apiEndpoint: NonEmptyString,
  tenant: APIParameters["tenant"],
  locale: SupportedLocales,
  channel: Channel
): TaskEither<string, LocaleMessages> {
  return pipe(
    importMessages(apiEndpoint, tenant, locale, channel),
    taskEither.mapLeft(error => {
      console.error(error);
      return error;
    })
  );
}

function importMomentLocale(lang: SupportedLocales): Promise<void> {
  // english is the default in moment
  if (lang === "en") {
    return Promise.resolve();
  }
  // import the right language
  return import("moment/locale/" + lang + ".js");
}

const LOCALE_STORAGE_KEY = "locale";

export function setPersistedLocale(locale: option.Option<SupportedLocales>) {
  if (option.isSome(locale)) {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale.value);
  } else {
    localStorage.removeItem(LOCALE_STORAGE_KEY);
  }
}

export function getPersistedLocale(): option.Option<SupportedLocales> {
  return pipe(
    either.tryCatch(
      () => localStorage.getItem(LOCALE_STORAGE_KEY),
      either.toError
    ),
    either.chainW(SupportedLocales.decode),
    option.fromEither
  );
}

function getTenant(apiEndpoint: NonEmptyString): TaskEither<unknown, Tenant> {
  return pipe(
    taskEither.tryCatch(
      () =>
        fetch(
          `${apiEndpoint.replace(
            /\/+$/,
            ""
          )}/utilities/tenant/noauth?component=eShopFE`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        ).then(body => body.json()),
      () => "Failed to retrieve tenant"
    ),
    taskEither.chainEitherKW(Tenant.decode)
  );
}

const constLoading = () => <BasicFullScreenLoader />;

const appFailure = (msg: string) => (
  <>
    <Space units={10} />
    <ErrorBanner children={unsafeLocalizedString(msg)} />
  </>
);

export function AppProvider(props: Props) {
  const apiEndpoint = useAPIEndpoint();

  return pipe(
    useRemoteData(useMemo(() => getTenant(apiEndpoint), [apiEndpoint])),
    remoteData.fold(
      constLoading,
      () => appFailure("Internal server error while fetching tenant"),
      tenant => <TenantAppProvider {...props} tenant={tenant} />
    )
  );
}

function TenantAppProvider(props: Props & { tenant: Tenant }) {
  const apiEndpoint = useAPIEndpoint();

  const [locale, setLocale] = useState(
    pipe(
      props.locale,
      option.fromNullable,
      option.alt(localeFromUrl),
      option.alt(getPersistedLocale),
      option.getOrElse(() =>
        props.forceLocalLang
          ? defaultLocaleFromTenant(props.tenant)
          : defaultBrowserLocale()
      )
    )
  );

  const [forceUpdateRemoteConfig, setForceUpdateRemoteConfig] = useState(1);

  const updateRemoteConfig = useCallback(
    () => setForceUpdateRemoteConfig(n => n + 1),
    []
  );

  const [remoteConfigOverride, setRemoteConfigOverride] = useState<
    Partial<RemoteConfig>
  >({});

  const remoteConfig = useRemoteData(
    useMemo(
      () => importConfigurations(apiEndpoint, locale, remoteConfigOverride),
      [apiEndpoint, locale, forceUpdateRemoteConfig, remoteConfigOverride]
    )
  );

  const [forceUpdateTranslations, setForceUpdateTranslations] = useState(1);
  const updateTranslations = useCallback(
    () => setForceUpdateTranslations(n => n + 1),
    []
  );

  const buildVersion = useRemoteData(
    useMemo(
      () =>
        pipe(
          taskEither.tryCatch(getCurrentBuildVersion, () => {
            console.log("Rejected retrieving initial build version");
            return "";
          }),
          taskEither.bimap(
            () => "",
            text => text
          )
        ),
      []
    )
  );

  const appContext = useMemo(
    () =>
      pipe(
        { remoteConfig, buildVersion },
        sequenceS(remoteData.remoteData),
        remoteData.map(({ remoteConfig: config, buildVersion }) => ({
          config,
          locale,
          apiParameters: {
            tenant: props.tenant,
            channel: props.channel,
            language: locale,
          },
          buildVersion,
          setLocale: (locale: SupportedLocales) => {
            setPersistedLocale(option.some(locale));
            setLocale(locale);
            import("moment").then(moment => {
              importMomentLocale(locale).then(() => {
                moment.locale(locale);
              });
            });
          },
          updateTranslations,
          updateRemoteConfig,
          setRemoteConfigOverride,
        }))
      ),
    [
      locale,
      props.channel,
      remoteConfig,
      props.tenant,
      updateTranslations,
      buildVersion,
    ]
  );

  const messages = useMemo(
    () => importTranslations(apiEndpoint, props.tenant, locale, props.channel),
    [apiEndpoint, props.tenant, locale, forceUpdateTranslations, props.channel]
  );

  const [dismissedHostMessage, setDismissedHostMessage] = useState(false);
  const canShowHostDialog = (env: string) => {
    const dbnQaOrProd =
      window.location.hostname.includes("dbnocpqa") ||
      window.location.hostname.includes("dbnocpprod");
    return !dismissedHostMessage && env === "uat" && dbnQaOrProd;
  };

  return pipe(
    useRemoteData(messages),
    remoteData.fold(
      constLoading,
      () => appFailure("Internal server error while fetching messages"),
      messages =>
        pipe(
          appContext,
          remoteData.fold(
            constLoading,
            () => appFailure("Internal server error while fetching settings"),
            appContext => (
              <AppContext.Provider value={option.some(appContext)}>
                <IntlProvider locale={locale} messages={messages}>
                  {/* This AuthenticationContextProvider is added here to provide authContext
                      to PortalStatusProvider requests when the user is logged in */}
                  <AuthenticationContextProvider
                    authInfo={option.none}
                    potentialClientToken={taskEither.of(option.none)}
                  >
                    <PortalStatusProvider locale={messages.Loading}>
                      <>
                        {canShowHostDialog(appContext.config.environment) && (
                          <Dialog
                            variant="center"
                            size="small"
                            title={unsafeLocalizedString(
                              "Running on host: " + window.location.hostname
                            )}
                            onDismiss={option.some(() =>
                              setDismissedHostMessage(true)
                            )}
                            actions={[
                              {
                                variant: "primary",
                                label: "OK" as LocalizedString,
                                action: () => setDismissedHostMessage(true),
                              },
                            ]}
                            icon={WarningIcon}
                          />
                        )}
                        <LoadingStatusProvider>
                          {props.children}
                        </LoadingStatusProvider>

                        <DocumentTitle />
                      </>
                    </PortalStatusProvider>
                  </AuthenticationContextProvider>
                </IntlProvider>
              </AppContext.Provider>
            )
          )
        )
    )
  );
}
