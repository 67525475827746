import { LoadingButton } from "design-system";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../intl";

type Props = {
  action: TaskEither<unknown, unknown>;
  disabled?: boolean;
};

export function NextButton(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <LoadingButton
      variant="primary"
      type="submit"
      labels={{
        normal: formatMessage("Next"),
        success: formatMessage("Next"),
        error: formatMessage("Next"),
        loading: formatMessage("Next"),
      }}
      size="default"
      data-test-id="next_button"
      action={props.action}
      disabled={props.disabled}
    />
  );
}
