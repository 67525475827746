import {
  Dialog,
  Space,
  SuccessNoCircleIcon,
  TextareaField,
  useForm,
  Banner,
  Box,
  validators,
  PositiveInteger,
} from "design-system";

import { useState } from "react";
import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { DocumentNoteUploadError } from "./domain";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { useFormatMessage } from "../../../intl";
import { useValidators } from "../../useValidators";
import { TaskEither } from "fp-ts/TaskEither";
import { DocumentUploadErrorBanner } from "./DocumentUploadErrorBanner";

export const AddDocumentNote = (props: {
  cancelProcess: () => void;
  goToUploadedSuccessfully: () => void;
  onDocumentConfirm: (
    note: Option<string>
  ) => TaskEither<DocumentNoteUploadError, unknown>;
  maxLengthNote: PositiveInteger;
  fileSizeMax: number;
  isNoteMandatory?: boolean;
}) => {
  const [noteUploadErrors, setNoteUploadErrors] = useState<
    Option<DocumentNoteUploadError>
  >(option.none);
  const formatMessage = useFormatMessage();

  const { maxLength } = useValidators();
  const { fieldProps, handleSubmit, setValues } = useForm(
    {
      initialValues: {
        note: option.none as Option<NonEmptyString>,
      },
      fieldValidators: () => ({
        note: validators.validateIfDefined(
          validators.inSequence(
            validators.nonBlankString(
              formatMessage(
                "Mortgage.Documents.documentUploadDialog.documentNote.error.noBlankString"
              )
            ),
            validators.notRegex(
              /[\\/:*?"<>|]+/,
              formatMessage(
                "Mortgage.Documents.documentUploadDialog.documentNote.error.noSpecialCharacters"
              )
            ),
            maxLength(props.maxLengthNote)
          )
        ),
      }),
    },
    {
      onSubmit: ({ note }) =>
        pipe(
          taskEither.fromIO<DocumentNoteUploadError, void>(() =>
            setNoteUploadErrors(option.none)
          ),
          taskEither.chain(() => props.onDocumentConfirm(note)),
          taskEither.bimap(
            err => setNoteUploadErrors(option.some(err)),
            props.goToUploadedSuccessfully
          )
        ),
    }
  );
  return (
    <Dialog
      title={formatMessage(
        "Mortgage.Documents.documentUploadDialog.documentNote.title"
      )}
      subtitle={formatMessage(
        "Mortgage.Documents.documentUploadDialog.documentNote.subtitle"
      )}
      onDismiss={option.some(props.cancelProcess)}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "Mortgage.Documents.documentUploadDialog.documentNote.button.confirm"
          ),
          action: handleSubmit,
        },
      ]}
      size="medium"
      variant="center"
      icon={SuccessNoCircleIcon}
    >
      <Box column>
        <TextareaField
          {...fieldProps("note")}
          onChange={noteStr =>
            setValues({
              note: pipe(NonEmptyString.decode(noteStr), option.fromEither),
            })
          }
          value={pipe(
            fieldProps("note").value,
            option.getOrElse(() => "")
          )}
          rows={2}
          autogrow={false}
          label={formatMessage(
            props.isNoteMandatory
              ? "Mortgage.Documents.documentUploadDialog.documentNote.note.label.mandatory"
              : "Mortgage.Documents.documentUploadDialog.documentNote.note.label"
          )}
          placeholder={formatMessage(
            "Mortgage.Documents.documentUploadDialog.documentNote.note.placeholder"
          )}
        />
        <Space units={4} />
        <Banner
          type="warning"
          content={formatMessage(
            "Mortgage.Documents.documentUploadDialog.documentNote.note.warning"
          )}
          title={option.none}
          actions={option.none}
          onDismiss={option.none}
        />
      </Box>
      <DocumentUploadErrorBanner
        uploadErrors={noteUploadErrors}
        fileSizeMax={props.fileSizeMax}
      />
    </Dialog>
  );
};
