import { Space, Banner } from "design-system";

import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constNull, pipe } from "fp-ts/function";
import { DocumentUploadError } from "./domain";
import { useFormatMessage } from "../../../intl";
import { fileTypesExts } from "../../../MortgageDashboard/Documents/domain";

export const DocumentUploadErrorBanner = (props: {
  uploadErrors: Option<DocumentUploadError>;
  fileSizeMax: number; //only for UnsupportedDocumentSize error
}) => {
  const formatMessage = useFormatMessage();
  return pipe(
    props.uploadErrors,
    option.fold(constNull, error => (
      <>
        <Space units={4} />
        <Banner
          content={(() => {
            switch (error) {
              case "GenericError":
                return formatMessage(
                  "Documents.documentUploadDialog.upload.error.GenericError"
                );
              case "UnsupportedDocumentFormat":
                return formatMessage(
                  "Documents.documentUploadDialog.upload.error.UnsupportedDocumentFormat",
                  {
                    types: fileTypesExts
                      .filter(
                        (elem, index, self) => index === self.indexOf(elem)
                      )
                      .join(", "),
                  }
                );
              case "POIUnsupportedDocumentFormat":
                return formatMessage(
                  "Documents.documentUploadDialog.upload.error.UnsupportedDocumentFormat",
                  {
                    types: ".pdf",
                  }
                );
              case "UnsupportedDocumentSize":
                return formatMessage(
                  "Documents.documentUploadDialog.upload.error.UnsupportedDocumentSize",
                  {
                    size: props.fileSizeMax,
                  }
                );
            }
          })()}
          title={option.none}
          actions={option.none}
          type="error"
        />
      </>
    ))
  );
};
