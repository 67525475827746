import { OTP, unsafePositiveInteger } from "design-system";
import * as t from "io-ts";
import { apiCall } from "../APICall";
import { BankerDetails } from "./domain";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { optionFromUndefined } from "../globalDomain";
import { withFallback } from "io-ts-types/lib/withFallback";
import { OtpGenerationError, OtpVerifyError } from "../OTP/domain";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
const BankerActionsOutput = t.type(
  {
    actions: t.array(
      t.type({ action: t.string, enabled: t.boolean }, "BankerAction")
    ),
  },
  "bankerActionsOutput"
);

export type BankerActionsOutput = t.TypeOf<typeof BankerActionsOutput>;

export const bankerActions = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "bankerActions"],
  inputCodec: t.void,
  outputCodec: BankerActionsOutput,
});

export type BankerDetailsOutput = t.TypeOf<typeof BankerDetails>;

export const bankerDetails = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "banker", "details"],
  inputCodec: t.void,
  outputCodec: BankerDetails,
});

export const ApplicationAuthorizationInput = t.type({
  applicationId: NonEmptyString,
});

export type ApplicationAuthorizationInput = t.TypeOf<
  typeof ApplicationAuthorizationInput
>;

export const ExistingClientAuthenticationMode = t.keyof(
  {
    ID_IN_BRANCH: true,
    SMARTKEY: true,
  },
  "ExistingClientAuthenticationMode"
);
export type ExistingClientAuthenticationMode = t.TypeOf<
  typeof ExistingClientAuthenticationMode
>;

export const PotentialClientAuthenticationMode = t.keyof(
  {
    SMS_OTP: true,
  },
  "PotentialClientAuthenticationMode"
);
export type PotentialClientAuthenticationMode = t.TypeOf<
  typeof PotentialClientAuthenticationMode
>;

export const AuthenticationMode = t.union([
  ExistingClientAuthenticationMode,
  PotentialClientAuthenticationMode,
]);

export type AuthenticationMode = t.TypeOf<typeof AuthenticationMode>;

export const ApplicationAuthorizationOutput = t.type({
  userAuthorized: t.boolean,
  clientAuthenticationRequired: withFallback(t.boolean, false),
  allowedClientAuthenticationTypes: optionFromUndefined(
    nonEmptyArray(AuthenticationMode)
  ),
  clientPhoneNumber: optionFromUndefined(NonEmptyString),
});

export type ApplicationAuthorizationOutput = t.TypeOf<
  typeof ApplicationAuthorizationOutput
>;

const eqApplicationAuthorization = eq.getStructEq({
  applicationId: eq.eqString,
});

export const applicationAuthorization = apiCall({
  inputEq: eqApplicationAuthorization,
  path: ["authorization", "application", "checkAuthorization"],
  inputCodec: ApplicationAuthorizationInput,
  outputCodec: ApplicationAuthorizationOutput,
});

export const ApplicationClientAuthenticatedInput = t.type({
  applicationId: NonEmptyString,
});

export type ApplicationClientAuthenticatedInput = t.TypeOf<
  typeof ApplicationClientAuthenticatedInput
>;

const eqApplicationClientAuthenticated = eq.getStructEq({
  applicationId: eq.eqString,
});

export const ApplicationClientAuthenticatedOutput = t.type({
  clientAuthenticated: t.boolean,
});

export type ApplicationClientAuthenticatedOutput = t.TypeOf<
  typeof ApplicationClientAuthenticatedOutput
>;

export const applicationClientAuthenticated = apiCall({
  inputEq: eqApplicationClientAuthenticated,
  path: ["authorization", "application", "checkClientAuthentication"],
  inputCodec: ApplicationClientAuthenticatedInput,
  outputCodec: ApplicationClientAuthenticatedOutput,
});

// generateOTP can be called limitless so no output is returned

export const ApplicationOtpGenerationInput = t.type({
  phoneNumber: t.string,
  applicationId: t.string,
});

export type ApplicationOtpGenerationInput = t.TypeOf<
  typeof ApplicationOtpGenerationInput
>;

export const generateOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "branch", "client", "identification", "otp"],
  inputCodec: ApplicationOtpGenerationInput,
  outputCodec: t.unknown,
  errorCodec: OtpGenerationError,
});

export const ApplicationOtpVerifyInput = t.type({
  // 1 since not really used for encoding in input to API
  otp: OTP(unsafePositiveInteger(1)),
  applicationId: t.string,
});

export type ApplicationOtpVerifyInput = t.TypeOf<
  typeof ApplicationOtpVerifyInput
>;

export const verifyOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "branch", "client", "identification", "verify"],
  inputCodec: ApplicationOtpVerifyInput,
  errorCodec: OtpVerifyError,
});

export const bankerLogout = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "banker", "logout"],
  inputCodec: t.void,
});
