import {
  Dialog,
  FileUploader,
  Body,
  Space,
  CloseIcon,
  SpinnerIcon,
  BadgeIcon,
  Heading,
  Box,
  RadioGroup,
  LocalizedString,
  FileIcon,
  SmartphoneIcon,
  Children,
  Stack,
  PositiveInteger,
  useIsMobileLayout,
  link,
} from "design-system";

import { Suspense, useState } from "react";
import { option, taskEither, task } from "fp-ts";
import { Option } from "fp-ts/Option";
import { flow, constNull, identity, pipe, constant } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Step, DocumentUploadError, DocumentNoteUploadError } from "./domain";
import { MobileUploadStatusDialogPOI as MobileUploadStatusDialog } from "../../../UploadDocuments/MobileUploadStatusDialogPOI";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { LocaleKey, RuntimeLocaleKey, useFormatMessage } from "../../../intl";
import * as documentApi from "../../documentAPI";
import { fileTypesExts } from "../../../MortgageDashboard/Documents/domain";
import { TaskEither } from "fp-ts/TaskEither";
import {
  isMainApplicant,
  SelectedApplicant,
} from "../../../MortgageDashboard/mortgageDashboardUtils";
import { ChooseMobileDeviceDialog } from "../../../UploadDocuments/ChooseMobileDeviceDialog";
import { useIsRemoteChannel } from "../../../useChannel";
import { RemoteMobileDeviceDialog } from "../../../UploadDocuments/RemoteMobileDeviceDialog";
import { mobileRecipients } from "../../../UploadDocuments/api";
import {
  CoApplicantInput,
  UploadDocumentFlowType,
} from "../../../globalDomain";
import { useQuery } from "../../../useAPI";
import * as remoteData from "../../../RemoteData";
import { ProofOfIncomeUploadSameDeviceDialog } from "../../../UploadDocuments/MobileIdUpload/ProofOfIncomeUploadFlow/ProofOfIncomeUploadSameDeviceDialog";
import { WaitingUploadedStatusDialog } from "../../../StandardLoan/UploadDocuments/WaitingUploadedStatusDialog";
import { DocumentUploadErrorBanner } from "./DocumentUploadErrorBanner";
import { AddDocumentNote } from "./AddDocumentNote";
import { getTranslationProductPrefix } from "./utils";

type DocumentUploadProps = {
  onDismiss: () => void;
  goToDocumentUploading: () => void;
  goToDocumentNote: () => void;
  onDocumentUpload: (file: File) => TaskEither<DocumentUploadError, unknown>;
  goBackWithError: (error: Option<DocumentUploadError>) => void;
  error: Option<DocumentUploadError>;
  acceptableFileTypes: documentApi.DocumentMime[];
  fileSizeMax: number;
  documentName?: string;
  isProofOfIncome: boolean;
};

const DocumentUpload = (props: DocumentUploadProps) => {
  const formatMessage = useFormatMessage();
  const [uploadErrors, setUploadErrors] = useState<Option<DocumentUploadError>>(
    props.error
  );

  return (
    <Dialog
      title={formatMessage("Documents.documentUploadDialog.upload.title", {
        documentName: "",
      })}
      onDismiss={option.some(props.onDismiss)}
      actions={[
        {
          variant: "secondary",
          label: formatMessage(
            "Documents.documentUploadDialog.upload.button.cancel"
          ),
          action: props.onDismiss,
        },
      ]}
      size="medium"
      variant="left"
    >
      <Stack column>
        <Body size="xx-small" weight="regular">
          {formatMessage(
            "Documents.documentUploadDialog.upload.maxSizeAllowed",
            {
              size: props.fileSizeMax,
            }
          )}
        </Body>
        <Body size="xx-small" weight="regular">
          {formatMessage(
            "Documents.documentUploadDialog.upload.supportedFormats",
            {
              types: fileTypesExts
                .filter((elem, index, self) => index === self.indexOf(elem))
                .join(", "),
            }
          )}
        </Body>
        {props.documentName ? (
          <Body size="xx-small" weight="regular">
            {formatMessage(
              "Documents.documentUploadDialog.upload.documentName.label",
              {
                documentName: props.documentName,
              }
            )}
          </Body>
        ) : (
          <></>
        )}
        {props.isProofOfIncome && (
          <Body size="xx-small" weight="regular">
            {[
              formatMessage(
                "CF.Documents.documentUploadDialog.upload.ProofOfIncome.template"
              ),
              link(
                formatMessage(
                  "CF.Documents.documentUploadDialog.upload.ProofOfIncome.template.link"
                ),
                formatMessage(
                  "CF.Documents.documentUploadDialog.upload.ProofOfIncome.template.linkLabel"
                )
              ),
            ]}
          </Body>
        )}
        {props.isProofOfIncome && (
          <Body size="xx-small" weight="regular">
            {[
              formatMessage(
                "CF.Documents.documentUploadDialog.upload.ProofOfIncome.manual"
              ),
              link(
                formatMessage(
                  "CF.Documents.documentUploadDialog.upload.ProofOfIncome.manual.link"
                ),
                formatMessage(
                  "CF.Documents.documentUploadDialog.upload.ProofOfIncome.manual.linkLabel"
                )
              ),
            ]}
          </Body>
        )}
        <Space units={8} />
        <FileUploader
          accept={props.acceptableFileTypes}
          uploaderLabel={formatMessage(
            "Documents.documentUploadDialog.upload.uploadButton.placeholder.1"
          )}
          orLabel={formatMessage(
            "Documents.documentUploadDialog.upload.uploadButton.placeholder.2"
          )}
          buttonLabel={formatMessage(
            "Documents.documentUploadDialog.upload.uploadButton.placeholder.3"
          )}
          onSelected={file =>
            pipe(
              taskEither.fromIO<DocumentNoteUploadError, void>(() =>
                setUploadErrors(option.none)
              ),
              taskEither.chain(() => {
                props.goToDocumentUploading();
                return props.onDocumentUpload(file);
              }),
              taskEither.bimap(error => {
                props.goBackWithError(option.some(error));
              }, props.goToDocumentNote)
            )()
          }
        />
        <DocumentUploadErrorBanner
          uploadErrors={uploadErrors}
          fileSizeMax={props.fileSizeMax}
        />
      </Stack>
    </Dialog>
  );
};

const CancelProcessDialog = (props: {
  onDismiss: () => void;
  goBack: () => void;
}) => {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      title={formatMessage(
        "Mortgage.Documents.documentUploadDialog.cancelProcess.title"
      )}
      subtitle={formatMessage(
        "Mortgage.Documents.documentUploadDialog.cancelProcess.subtitle"
      )}
      onDismiss={option.none}
      actions={[
        {
          variant: "secondary",
          label: formatMessage(
            "Mortgage.Documents.documentUploadDialog.cancelProcess.button.returnToProcess"
          ),
          action: props.goBack,
        },
        {
          variant: "danger",
          label: formatMessage(
            "Mortgage.Documents.documentUploadDialog.cancelProcess.button.cancelProcess"
          ),
          action: props.onDismiss,
        },
      ]}
      size="medium"
      variant="center"
      iconColor="danger"
      icon={CloseIcon}
    />
  );
};

const DocumentUploadingDialog = (props: { cancelProcess: () => void }) => {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      title={formatMessage("Documents.documentUploadDialog.upload.title", {
        documentName: "",
      })}
      onDismiss={option.some(props.cancelProcess)}
      actions={[
        {
          variant: "secondary",
          label: formatMessage(
            "Documents.documentUploadDialog.upload.button.cancel"
          ),
          action: props.cancelProcess,
        },
      ]}
      size="medium"
      variant="left"
    >
      <Box hAlignContent="center" column>
        <SpinnerIcon size="xx-large" />
        <Heading size="medium" weight="regular">
          {formatMessage(
            "Documents.documentUploadDialog.upload.uploadingTitle"
          )}
        </Heading>
      </Box>
    </Dialog>
  );
};

const DocumentUploadedSuccessfully = (props: { onDismiss: () => void }) => {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      title={formatMessage(
        "Mortgage.Documents.documentUploadDialog.uploadedSuccessfully.title"
      )}
      subtitle={formatMessage(
        "Mortgage.Documents.documentUploadDialog.uploadedSuccessfully.subtitle"
      )}
      onDismiss={option.some(props.onDismiss)}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            "Mortgage.Documents.documentUploadDialog.uploadedSuccessfully.button.ok"
          ),
          action: props.onDismiss,
        },
      ]}
      size="medium"
      variant="center"
      icon={BadgeIcon}
    />
  );
};

type UploadMode = "Filesystem" | "Mobile";
type UploadModeDialogProps = {
  onDismiss: () => unknown;
  chooseFilesystem: () => unknown;
  chooseMobile: () => unknown;
  productType: UploadDocumentFlowType;
} & CoApplicantInput;

export function UploadModeDialog(props: UploadModeDialogProps) {
  const formatMessage = useFormatMessage();
  const isRemote = useIsRemoteChannel();

  const [recipients] = useQuery(mobileRecipients, {
    coApplicant: props.coApplicant,
    linkPurpose: "proofOfIncome",
  });
  const translationPrefix = getTranslationProductPrefix(props.productType);
  const formatUploadModeTitle = (uploadMode: UploadMode): LocalizedString => {
    switch (uploadMode) {
      case "Filesystem":
        return formatMessage(
          `${translationPrefix}.Documents.documentUploadDialog.uploadMode.title.Filesystem` as LocaleKey
        );
      case "Mobile":
        return formatMessage(
          `${translationPrefix}.Documents.documentUploadDialog.uploadMode.title.Mobile` as LocaleKey
        );
    }
  };

  const formatUploadModeDescription = (
    uploadMode: UploadMode
  ): LocalizedString => {
    switch (uploadMode) {
      case "Filesystem":
        return formatMessage(
          `${translationPrefix}.Documents.documentUploadDialog.uploadMode.subtitle.Filesystem` as LocaleKey
        );
      case "Mobile":
        return formatMessage(
          `${translationPrefix}.Documents.documentUploadDialog.uploadMode.subtitle.Mobile` as LocaleKey
        );
    }
  };

  const formatUploadModeIcon = (uploadMode: UploadMode): Children => {
    switch (uploadMode) {
      case "Filesystem":
        return <FileIcon size="medium" />;
      case "Mobile":
        return <SmartphoneIcon size="medium" />;
    }
  };

  const submit = (uploadMode: UploadMode) => {
    switch (uploadMode) {
      case "Filesystem":
        return props.chooseFilesystem();
      case "Mobile":
        return props.chooseMobile();
    }
  };

  const [mode, setMode] = useState<UploadMode>("Filesystem");
  const options: NonEmptyArray<UploadMode> = ["Filesystem", "Mobile"];

  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage(
        `${translationPrefix}.Documents.documentUploadDialog.uploadMode.title` as LocaleKey
      )}
      onDismiss={option.some(props.onDismiss)}
      actions={[
        {
          variant: "primary",
          action: () => submit(mode),
          label: formatMessage(
            `${translationPrefix}.Documents.documentUploadDialog.uploadMode.button.upload` as LocaleKey
          ),
        },
      ]}
    >
      <RadioGroup
        name="uploadMode"
        variant="vertical"
        value={option.some(mode)}
        onChange={option.map(setMode)}
        options={options}
        optionKey={identity}
        isOptionDisabled={opt =>
          pipe(
            recipients,
            remoteData.fold(
              () => opt === "Mobile",
              () => opt === "Mobile",
              recipients => {
                if (isRemote) {
                  return opt === "Mobile" && recipients.Client.disabled;
                } else {
                  return (
                    opt === "Mobile" &&
                    recipients.Banker.disabled &&
                    recipients.Client.disabled
                  );
                }
              }
            )
          )
        }
        renderOption={formatUploadModeTitle}
        renderOptionChildren={flow(formatUploadModeDescription, option.some)}
        renderOptionIcon={flow(formatUploadModeIcon, option.some)}
        issuesType={option.none}
      />
    </Dialog>
  );
}

const KOGoToBranch = (props: {
  goBack: () => unknown;
  productType: UploadDocumentFlowType;
}) => {
  const formatMessage = useFormatMessage();
  const translationPrefix = getTranslationProductPrefix(props.productType);
  return (
    <Dialog
      icon={CloseIcon}
      variant="center"
      size="medium"
      title={formatMessage(
        `${translationPrefix}.Documents.documentUploadDialog.KOGoToBranch.title` as LocaleKey
      )}
      onDismiss={option.some(props.goBack)}
      actions={[
        {
          variant: "primary",
          action: props.goBack,
          label: formatMessage(
            `${translationPrefix}.Documents.documentUploadDialog.KOGoToBranch.button.back` as LocaleKey
          ),
        },
      ]}
    />
  );
};

export type MobileUploadProp =
  | {
      mobileUploadEnabled: false;
    }
  | {
      mobileUploadEnabled: true;
      docTypeId: NonEmptyString;
      applicationElementID: Option<NonEmptyString>;
      clientID?: Option<NonEmptyString>;
      documentTypeName?: Option<NonEmptyString>;
      section?: Option<NonEmptyString>;
      translationCode?: Option<RuntimeLocaleKey>;
    };

export type DocumentUploadDialogProps = {
  onDismiss: () => void;
  mobileUploadEnabled: boolean;
  onDocumentUpload: (file: File) => TaskEither<DocumentUploadError, unknown>;
  onDocumentConfirm: (
    note: Option<string>
  ) => TaskEither<DocumentNoteUploadError, unknown>;
  onDocumentCancel: () => void;
  selectedApplicant: SelectedApplicant;
  acceptableFileTypes: documentApi.DocumentMime[];
  skipNotes?: boolean;
  maxLengthNote: Option<PositiveInteger>;
  fileSizeMax: number;
  productType: Option<UploadDocumentFlowType>;
  documentName?: string;
  isNoteMandatory?: boolean;
} & MobileUploadProp;

export const DocumentUploadDialog = (props: DocumentUploadDialogProps) => {
  const initialStep: Step = props.mobileUploadEnabled
    ? { id: "chooseHowToUpload" }
    : { id: "filesystemUpload", error: option.none };
  const [step, setStep] = useState<Step>(initialStep);
  const isRemote = useIsRemoteChannel();
  const isMobileLayout = useIsMobileLayout();

  const coApplicant = isMainApplicant(props.selectedApplicant)
    ? option.none
    : option.some(props.selectedApplicant);

  const docTypeId = props.mobileUploadEnabled
    ? option.some(props.docTypeId)
    : option.some("" as NonEmptyString);

  const appElementId = props.mobileUploadEnabled
    ? props.applicationElementID
    : option.some("" as NonEmptyString);

  const clientId =
    props.mobileUploadEnabled && props.clientID
      ? props.clientID
      : option.some("" as NonEmptyString);

  const docTypeName =
    props.mobileUploadEnabled && props.documentTypeName
      ? props.documentTypeName
      : option.some("" as NonEmptyString);

  const section =
    props.mobileUploadEnabled && props.section
      ? props.section
      : option.some("" as NonEmptyString);

  const translationCode =
    props.mobileUploadEnabled && props.translationCode
      ? props.translationCode
      : option.some("" as RuntimeLocaleKey);

  switch (step.id) {
    case "chooseHowToUpload":
      return (
        <UploadModeDialog
          chooseFilesystem={() =>
            setStep({ id: "filesystemUpload", error: option.none })
          }
          chooseMobile={() =>
            setStep({
              id:
                isRemote || isMobileLayout
                  ? "remoteChooseRecipient"
                  : "inPersonChooseRecipient",
            })
          }
          onDismiss={props.onDismiss}
          coApplicant={coApplicant}
          productType={pipe(
            props.productType,
            option.getOrElse(constant("MORTGAGE" as UploadDocumentFlowType))
          )}
        />
      );
    case "inPersonChooseRecipient":
      return (
        <ChooseMobileDeviceDialog
          type="ProofOfIncome"
          onSelect={recipient =>
            setStep({ id: "remoteUploadStatus", recipient })
          }
          onDismiss={props.onDismiss}
          onUploadAgain={option.none}
          onMockScannerUpload={option.none}
          coApplicant={coApplicant}
        />
      );
    case "remoteChooseRecipient":
      return (
        <RemoteMobileDeviceDialog
          onDismiss={props.onDismiss}
          onSelectRecipient={recipient =>
            setStep({ id: "remoteUploadStatus", recipient })
          }
          onUploadFromSameDevice={() =>
            setStep({ id: "mobileUploadSameDevice" })
          }
          onMockScannerUpload={option.none}
          mobileRecipientType="Client"
          type="DocumentUpload"
          coApplicant={coApplicant}
        />
      );
    case "remoteUploadStatus":
      return (
        <MobileUploadStatusDialog
          flowType="ProofOfIncome"
          documentIdentificationFlow="Primary"
          recipient={step.recipient}
          onDismiss={props.onDismiss}
          onMaxAttemptsReached={() => {
            if (isRemote) {
              setStep({ id: "KOGoToBranch" });
            } else {
              setStep({
                id: "chooseHowToUpload",
              });
            }
          }}
          onUploadCompleted={task.fromIO(() =>
            props.skipNotes
              ? setStep({ id: "uploadedSuccessfully" })
              : setStep({
                  id: "documentNote",
                  uploadBySmsLink: true,
                })
          )}
          coApplicant={coApplicant}
          docTypeId={
            props.mobileUploadEnabled
              ? option.some(props.docTypeId)
              : option.none
          }
          applicationElementID={
            props.mobileUploadEnabled ? props.applicationElementID : option.none
          }
        />
      );
    case "KOGoToBranch":
      return (
        <KOGoToBranch
          goBack={() => setStep({ id: "chooseHowToUpload" })}
          productType={pipe(
            props.productType,
            option.getOrElse(constant("MORTGAGE" as UploadDocumentFlowType))
          )}
        />
      );
    case "filesystemUpload":
      return (
        <DocumentUpload
          onDismiss={props.onDismiss}
          goToDocumentUploading={() => setStep({ id: "documentUploading" })}
          onDocumentUpload={props.onDocumentUpload}
          goToDocumentNote={() =>
            props.skipNotes
              ? setStep({ id: "uploadedSuccessfully" })
              : setStep({
                  id: "documentNote",
                  uploadBySmsLink: false,
                })
          }
          goBackWithError={error => setStep({ id: "filesystemUpload", error })}
          error={step.error}
          acceptableFileTypes={props.acceptableFileTypes}
          fileSizeMax={props.fileSizeMax}
          documentName={props.documentName}
          isProofOfIncome={props.mobileUploadEnabled}
        />
      );
    case "documentUploading":
      return (
        <DocumentUploadingDialog
          cancelProcess={() =>
            setStep({
              id: "cancelProcess",
              fromStage: { id: "documentUploading" },
            })
          }
        />
      );
    case "documentNote":
      const shouldWaitForSealing =
        step.uploadBySmsLink === true &&
        option.isSome(props.productType) &&
        (props.productType.value === "SL" ||
          props.productType.value === "CL" ||
          props.productType.value === "TL" ||
          props.productType.value === "RL" ||
          props.productType.value === "RPL");

      return (
        <AddDocumentNote
          cancelProcess={() =>
            setStep({
              id: "cancelProcess",
              fromStage: {
                id: "documentNote",
                uploadBySmsLink: step.uploadBySmsLink,
              },
            })
          }
          maxLengthNote={pipe(
            props.maxLengthNote,
            option.getOrElse(() => 252 as PositiveInteger)
          )}
          goToUploadedSuccessfully={() =>
            shouldWaitForSealing
              ? setStep({ id: "waitingUploadedStatus" })
              : setStep({ id: "uploadedSuccessfully" })
          }
          onDocumentConfirm={props.onDocumentConfirm}
          fileSizeMax={props.fileSizeMax}
          isNoteMandatory={props.isNoteMandatory}
        />
      );
    case "cancelProcess":
      return (
        <CancelProcessDialog
          onDismiss={() => {
            props.onDocumentCancel();
            props.onDismiss();
          }}
          goBack={() => setStep(step.fromStage)}
        />
      );
    case "uploadedSuccessfully":
      return <DocumentUploadedSuccessfully onDismiss={props.onDismiss} />;
    case "waitingUploadedStatus":
      return (
        <WaitingUploadedStatusDialog
          onDismiss={() => setStep({ id: "uploadedSuccessfully" })}
        />
      );
    case "mobileUploadSameDevice":
      return (
        <Suspense fallback={constNull}>
          <ProofOfIncomeUploadSameDeviceDialog
            onDismiss={props.onDismiss}
            productType={props.productType}
            coApplicant={coApplicant}
            parameters={option.some({
              docTypeId: docTypeId,
              applicationElementID: appElementId,
              clientID: clientId,
              documentTypeName: docTypeName,
              section: section,
              translationCode: translationCode,
            })}
          />
        </Suspense>
      );
  }
};
