import { useEffect } from "react";
import { useWindowSize } from "react-use";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { useDeviceUA } from "design-system";

export const useFixAndroidScrollInput = () => {
  const { isAndroid } = useDeviceUA();
  const { height } = useWindowSize();

  useEffect(() => {
    pipe(
      document.activeElement,
      option.fromNullable,
      option.map(e => {
        const shouldScroll =
          isAndroid &&
          (document.activeElement instanceof HTMLInputElement ||
            document.activeElement instanceof HTMLTextAreaElement);
        if (shouldScroll) {
          e.scrollIntoView({ block: "center" });
        }
      })
    );
  }, [height]);
};
