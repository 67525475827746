import { useEffect, useState } from "react";
import { useIdle } from "react-use";
import { useCountdown } from "./useCountdown";

export function useIdleCountdown(
  idleTime: number,
  showWarningBefore: number,
  onCountdownEnd: () => void
) {
  const idle = useIdle(1000);
  const [showWarning, setShowWarning] = useState(false);
  const [countdown, resetTimer] = useCountdown(
    idleTime,
    onCountdownEnd,
    !idle && !showWarning
  );

  useEffect(() => {
    if (!idle && !showWarning) {
      resetTimer();
    }
  }, [idle]);

  useEffect(() => {
    if (countdown * 1000 < showWarningBefore) {
      setShowWarning(true);
    }
  }, [idle, showWarning, countdown]);

  const dismissWarning = () => {
    resetTimer();
    setShowWarning(false);
  };

  return { countdown, showWarning, dismissWarning };
}
