import { MenuOption } from "../MenuDropdown/MenuDropdown";

export type ActionIconProps = Exit | Menu;

type Exit = {
  tag: "exit";
  onExit: () => unknown;
};

type Menu = {
  tag: "menu";
  menu: [MenuOption, ...MenuOption[]];
};

export function exit(onExit: () => unknown): ActionIconProps {
  return { tag: "exit", onExit };
}

export function menu(menu: [MenuOption, ...MenuOption[]]): ActionIconProps {
  return { tag: "menu", menu };
}

export function fold<T>(
  onMenu: (onMenu: [MenuOption, ...MenuOption[]]) => T,
  onExit: (onExit: () => unknown) => T
): (actionIcon: ActionIconProps) => T {
  return actionIcon => {
    switch (actionIcon.tag) {
      case "menu":
        return onMenu(actionIcon.menu);
      case "exit":
        return onExit(actionIcon.onExit);
    }
  };
}
