import { exit, menu, fold } from "./headerActionIcon";

export * from "./MainContent";
export * from "./Footer";

export const headerActions = {
  exit,
  menu,
  fold,
};
