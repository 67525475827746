import { forwardRef, useState } from "react";
import * as classes from "./IframePortal.treat";
import { useEvent } from "react-use";

type Props = {
  src: string;
  onMessageReceived: (message: unknown) => void;
  width: number;
  height: number;
};

export const IframePortal = forwardRef<HTMLIFrameElement, Props>(
  (props, forwardedRef) => {
    const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);
    const iframRef = (iframe: HTMLIFrameElement | null) => {
      setIframe(iframe);
      if (forwardedRef && typeof forwardedRef === "function") {
        forwardedRef(iframe);
      } else {
        forwardedRef!.current = iframe;
      }
    };

    useEvent("message", (message: MessageEvent) => {
      if (
        iframe &&
        iframe.contentWindow &&
        iframe.contentWindow === message.source
      ) {
        props.onMessageReceived(message.data);
      }
    });

    return (
      <div
        style={{
          width: props.width,
          height: props.height,
          position: "relative",
        }}
      >
        <iframe
          ref={iframRef}
          title="Iframe portal"
          className={classes.iframe}
          src={props.src}
        />
      </div>
    );
  }
);
