import { containerRowMaxWidth } from "design-system/lib/styleConstants";
import { Box } from "design-system/";
import { CommonProps } from "../CommonProps";

type Props = CommonProps & {
  imageUrl: string;
};

export function ContainerRowB(props: Props) {
  return (
    <Box
      className={props.className}
      height={188}
      width="100%"
      style={{
        maxWidth: containerRowMaxWidth,
        margin: "0 auto",
        backgroundImage: `url(${props.imageUrl})`,
      }}
    />
  );
}
