import { BuildConfigContext } from "./BuildConfigContext";
import { BuildConfig } from "./ConfigBlocks";
import { option } from "fp-ts";

type Props = BuildConfig & {
  children: JSX.Element;
};

export function BuildConfigProvider({ apiEndpoint, apiPort, children }: Props) {
  return (
    <BuildConfigContext.Provider
      value={option.some({
        apiEndpoint,
        apiPort,
      })}
    >
      {children}
    </BuildConfigContext.Provider>
  );
}
