import { Button, AngleLeftIcon, useIsMobileLayout } from "design-system";
import { boolean } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";

type Props = {
  action: () => unknown;
  disabled?: boolean;
  className?: string;
};

export function BackButton(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  return (
    <Button
      variant="text"
      size="default"
      icon={pipe(
        isMobileLayout,
        boolean.fold(
          () => AngleLeftIcon,
          () => undefined
        )
      )}
      label={formatMessage("Back")}
      action={props.action}
      disabled={props.disabled}
      className={props.className}
    />
  );
}
