import { Option } from "fp-ts/Option";
import { constant, pipe } from "fp-ts/function";
import {
  FeedbackBlock,
  Action,
  LocalizedString,
  TextChildren,
} from "design-system";
import { option } from "fp-ts";

type Props = {
  title: LocalizedString;
  description: Option<TextChildren>;
  action: Option<{ onClick: () => unknown; label: LocalizedString }>;
};

export function KOError(props: Props) {
  return (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={props.title}
      subheading={props.description}
      actions={pipe(
        props.action,
        option.map(
          action =>
            [
              {
                variant: "primary",
                action: action.onClick,
                label: action.label,
              },
            ] as [Action]
        ),
        option.getOrElseW(constant([] as []))
      )}
    />
  );
}
