import { constant } from "fp-ts/function";
import { ComponentProps } from "react";
import { containerRowMaxWidth } from "design-system/lib/styleConstants";
import { Box, Children, useBreakpoints, useIsTouchScreen } from "design-system";

type Props = {
  content: Children;
  component?: keyof JSX.IntrinsicElements;
  grow?: ComponentProps<typeof Box>["grow"];
};

export function ContainerRowC(props: Props) {
  const { foldBreakpoints } = useBreakpoints();
  const isTouchScreen = useIsTouchScreen();
  const paddingX = isTouchScreen
    ? 0
    : foldBreakpoints({
        ifDesktopWide: constant("10%"),
        ifDesktop: constant("10%"),
        ifTablet: constant("10%"),
        ifMobileLandscape: constant("8%"),
        ifMobilePortrait: constant("6%"),
      });
  return (
    <Box
      grow={props.grow != null ? props.grow : 1}
      shrink
      width="100%"
      style={{
        maxWidth: containerRowMaxWidth,
        margin: "0 auto",
        justifyContent: "space-around",
      }}
    >
      <Box
        component={props.component}
        column
        grow
        shrink
        style={{
          paddingLeft: paddingX,
          paddingRight: paddingX,
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
}
