import * as t from "io-ts";
import { sharedReducerConfig } from "../../../BranchExperience/useSharedReducer";

const State = t.type({
  open: t.boolean,
});

type State = t.TypeOf<typeof State>;

const OpenAction = t.type({
  type: t.literal("OpenAction"),
});
const CloseAction = t.type({
  type: t.literal("CloseAction"),
});

export const onOpenAction: Action = {
  type: "OpenAction",
};
export const onCloseAction: Action = {
  type: "CloseAction",
};

const Action = t.union([OpenAction, CloseAction]);
export type Action = t.TypeOf<typeof Action>;

function reducer(state: State, action: Action): State {
  if (action.type === "OpenAction") {
    return { ...state, open: true };
  }
  if (action.type === "CloseAction") {
    return { ...state, open: false };
  }
  return state;
}

export const reducerConfig = sharedReducerConfig(
  "VirtualCardsModal",
  State,
  Action,
  reducer
);
