import { constant, constNull } from "fp-ts/function";

import cx from "classnames";
import {
  containerRowMaxWidth,
  PaletteColor,
  spaceUnit,
} from "design-system/lib/styleConstants";
import { Box, Children, useBreakpoints } from "design-system";
import { headerHeight } from "../headerHeight";
import * as classes from "./Container.treat";

type Props = {
  leftContent: Children;
  rightContent: Children;
  leftColor: PaletteColor;
  centerColor: PaletteColor;
  centerContent?: Children;
  rightColor: PaletteColor;
  component?: keyof JSX.IntrinsicElements;
  isHeader?: boolean;
};

function SideContent({
  content,
  paddingSide,
  color,
}: {
  content?: Children;
  paddingSide: "left" | "right";
  color: PaletteColor;
}) {
  const { foldBreakpoints } = useBreakpoints();

  const sidePaddingX =
    foldBreakpoints({
      ifDesktopWide: constant(10),
      ifDesktop: constant(10),
      ifTablet: constant(10),
      ifMobileLandscape: constant(10),
      ifMobilePortrait: constant(6),
    }) * spaceUnit;

  const paddingStyle = (() => {
    switch (paddingSide) {
      case "left":
        return {
          paddingLeft: sidePaddingX,
        };
      case "right":
        return {
          paddingRight: sidePaddingX,
        };
    }
  })();
  return (
    <Box
      style={{
        ...paddingStyle,
        backgroundColor: color,
      }}
    >
      {content}
    </Box>
  );
}

function CenterContent({
  centerColor,
  centerContent,
}: {
  centerColor: PaletteColor;
  centerContent?: Children;
}) {
  return (
    <Box basis="100%" grow shrink style={{ backgroundColor: centerColor }}>
      {centerContent}
    </Box>
  );
}

export function ContainerRowA(props: Props) {
  const { foldBreakpoints } = useBreakpoints();
  const paddingX = foldBreakpoints({
    ifDesktopWide: constant("10%"),
    ifDesktop: constant("10%"),
    ifTablet: constant("8%"),
    ifMobileLandscape: constant("8%"),
    ifMobilePortrait: constant("6%"),
  });

  return (
    <Box
      component={props.component}
      height={headerHeight}
      className={cx({ [classes.topLeftCorner]: props.isHeader })}
      style={{ backgroundColor: props.leftColor }}
    >
      <Box grow shrink />
      <Box
        style={{
          width: "100%",
          maxWidth: containerRowMaxWidth,
          justifyContent: "space-around",
          margin: "0 auto",
        }}
      >
        <Box grow style={{ width: paddingX }} />
        <SideContent
          content={props.leftContent}
          paddingSide="right"
          color={props.leftColor}
        />
        {foldBreakpoints({
          ifDesktopWide: constant(
            <CenterContent
              centerColor={props.centerColor}
              centerContent={props.centerContent}
            />
          ),
          ifDesktop: constant(
            <CenterContent
              centerColor={props.centerColor}
              centerContent={props.centerContent}
            />
          ),
          ifTablet: constant(
            <CenterContent
              centerColor={props.centerColor}
              centerContent={props.centerContent}
            />
          ),
          ifMobileLandscape: constant(
            <CenterContent centerColor={props.centerColor} />
          ),
          ifMobilePortrait: constNull,
        })}
        <SideContent
          content={props.rightContent}
          paddingSide="left"
          color={props.rightColor}
        />
        <Box
          grow
          style={{ width: paddingX, backgroundColor: props.rightColor }}
        />
      </Box>
      <Box grow shrink style={{ backgroundColor: props.rightColor }} />
    </Box>
  );
}
