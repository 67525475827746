import { useMemo, useState } from "react";
import { constant, constFalse, constVoid, pipe } from "fp-ts/function";
import { useCommand } from "../../useAPI";
import * as companyApi from "./api";
import * as remoteData from "../../RemoteData";
import { AutocompleteInputField } from "design-system";
import { array, option, taskEither } from "fp-ts";
import { useRemoteData } from "../../useRemoteData";
import { useFormatMessage } from "../../intl";
import { autocompleteInputStatusFromRemoteData } from "./api";
import { CompanySuggestion } from "./domain";
import { useDebounceState } from "../useDebounceState";
import { Option } from "fp-ts/Option";
import { removeDiacritics } from "./utils";

type Props = Omit<
  React.ComponentProps<typeof AutocompleteInputField>,
  "suggestionsStatus" | "onSelectSuggestion" | "loadingMessage"
> & {
  onSelectSuggestion: (value: CompanySuggestion) => unknown;
  onValidationChange?: (value: boolean) => unknown;
  for: "Name" | "ICO";
  queryString?: string;
};

const MIN_COMPANY_SEARCH_TERM_LENGTH = 2;

export function CompanyField(props: Props) {
  const formatMessage = useFormatMessage();
  const fetchCompanySuggestions = useCommand(companyApi.companySuggestion, {
    skipTracking: true,
  });

  const [lastSelectedTerm, setLastSelectedTerm] = useState<Option<string>>(
    option.none
  );

  const termEquals = (
    previousTerm: Option<string>,
    currentTerm: string
  ): boolean => {
    return pipe(
      previousTerm,
      option.fold(constFalse, term => term === currentTerm)
    );
  };

  const queryTerms = useDebounceState(
    props.queryString !== undefined ? props.queryString : props.value,
    500
  );

  const companySuggestions = useRemoteData(
    useMemo(
      () =>
        queryTerms.length >= MIN_COMPANY_SEARCH_TERM_LENGTH &&
        !termEquals(lastSelectedTerm, queryTerms)
          ? pipe(
              fetchCompanySuggestions({
                text: removeDiacritics(queryTerms.toLowerCase()),
              }),
              taskEither.map(option.some)
            )
          : taskEither.right(option.none),
      [queryTerms]
    )
  );

  if (
    queryTerms.length >= MIN_COMPANY_SEARCH_TERM_LENGTH &&
    !termEquals(lastSelectedTerm, queryTerms)
  ) {
    pipe(
      companySuggestions,
      remoteData.fold(constVoid, constVoid, suggestions => {
        if (props.onValidationChange) {
          if (option.isSome(suggestions)) {
            pipe(
              suggestions.value,
              array.some(
                suggestion =>
                  suggestion[props.for === "Name" ? "name" : "id"] ===
                  props.queryString
              ),
              props.onValidationChange
            );
          } else if (
            props.queryString !== undefined &&
            props.queryString !== ""
          ) {
            props.onValidationChange(false);
          }
        }
      })
    );
  }

  return (
    <AutocompleteInputField
      showNativeTooltip
      {...props}
      suggestionsStatus={autocompleteInputStatusFromRemoteData(
        pipe(
          companySuggestions,
          remoteData.map(
            option.getOrElse(constant<companyApi.CompanySuggestionsOutput>([]))
          )
        ),
        suggestion => ({
          value: suggestion,
          label: formatMessage("Form.companyField.autocompleteLabel", {
            name: suggestion.name,
            ico: suggestion.id,
          }),
        })
      )}
      onSelectSuggestion={s => {
        const select = props.for === "Name" ? s.value.name : s.value.id;
        props.onChange(select);
        setLastSelectedTerm(option.some(select));
        props.onSelectSuggestion(s.value);
        if (props.onValidationChange) {
          props.onValidationChange(true);
        }
      }}
      loadingMessage={formatMessage("LoadingEllipsis")}
    />
  );
}
