import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { DocumentPurpose } from "../globalDomain";
import { LocaleKey } from "../intl";
import {
  DocumentIssue,
  PrimaryDocumentIssue,
  SecondaryDocumentIssue,
  SectionStatus,
} from "./domain";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { CitizenshipOrOther } from "../IdUpload/domain";

export const foldCitizenship: <T>(match: {
  CZE: () => T;
  SVK: () => T;
  OTHER: (citizenship: CitizenshipOrOther) => T;
}) => (citizenship: CitizenshipOrOther) => T = match => citizenship => {
  if (citizenship === "CZE") {
    return match.CZE();
  } else if (citizenship === "SVK") {
    return match.SVK();
  } else {
    return match.OTHER(citizenship);
  }
};

export function sectionStatusToPanelStatus(
  status: SectionStatus
): "complete" | "active" {
  return status === "OK" ? "complete" : "active";
}

export function foldDocumentPurpose<T>(
  documentPurpose: DocumentPurpose,
  match: {
    onPrimary: () => T;
    onSecondary: () => T;
  }
): T {
  switch (documentPurpose) {
    case "Primary":
      return match.onPrimary();
    case "Secondary":
      return match.onSecondary();
  }
}

export type MissableSecurityData =
  | "user-id"
  | "smart-key"
  | "security-pin"
  | "password-for-communication";

export function missingFieldsToTitleLocaleKey(
  missingFields: NonEmptyArray<MissableSecurityData>
): LocaleKey {
  if (missingFields.includes("user-id")) {
    return "ClientProfile.missingSecurityData.title.userId";
  } else if (missingFields.includes("smart-key")) {
    return "ClientProfile.missingSecurityData.title.smartKey";
  } else if (missingFields.includes("security-pin")) {
    return "ClientProfile.missingSecurityData.title.securityPin";
  } else {
    return "ClientProfile.missingSecurityData.title.password";
  }
}

export function missingFieldsToMessageLocaleKey(
  missingFields: NonEmptyArray<MissableSecurityData>
): LocaleKey {
  if (missingFields.includes("user-id")) {
    return "ClientProfile.missingSecurityData.message.userId";
  } else if (missingFields.includes("smart-key")) {
    if (missingFields.includes("security-pin")) {
      if (missingFields.includes("password-for-communication")) {
        return "ClientProfile.missingSecurityData.message.smartKey&securityPin&password";
      } else {
        return "ClientProfile.missingSecurityData.message.smartKey&securityPin";
      }
    } else {
      if (missingFields.includes("password-for-communication")) {
        return "ClientProfile.missingSecurityData.message.smartKey&password";
      } else {
        return "ClientProfile.missingSecurityData.message.smartKey";
      }
    }
  } else if (missingFields.includes("security-pin")) {
    if (missingFields.includes("password-for-communication")) {
      return "ClientProfile.missingSecurityData.message.securityPin&password";
    } else {
      return "ClientProfile.missingSecurityData.message.securityPin";
    }
  } else {
    return "ClientProfile.missingSecurityData.message.password";
  }
}

export function missingFieldsToConfirmLocaleKey(
  missingFields: NonEmptyArray<MissableSecurityData>
): LocaleKey {
  if (missingFields.includes("user-id")) {
    return "ClientProfile.missingUserId.confirm";
  } else {
    return "ClientProfile.missingSecurityData.confirm";
  }
}

export function foldIssue<
  I extends PrimaryDocumentIssue | SecondaryDocumentIssue,
  T
>(issue: I, match: Record<I, () => T>) {
  return match[issue]();
}

export const selectDocumentIssue: (
  primaryIssue: Option<PrimaryDocumentIssue>,
  secondaryIssue: Option<SecondaryDocumentIssue>
) => Option<DocumentIssue> = (primaryIssue, secondaryIssue) =>
  pipe(
    primaryIssue,
    option.fold(
      () =>
        pipe(
          secondaryIssue,
          option.map(issue => ({ documentPurpose: "Secondary", issue }))
        ),
      issue =>
        option.some<DocumentIssue>({
          documentPurpose: "Primary",
          issue,
        })
    )
  );
