import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { ComponentProps } from "react";
import { Box, Space } from "design-system";
import { HeaderCZ } from "./HeaderCZ";
import * as classes from "./HeaderMobile.treat";
import { Logo } from "./Logo";
import { LogoOpen } from "./LogoOpen";

type Props = Pick<ComponentProps<typeof HeaderCZ>, "onLogoClick">;

export function HeaderMobileCZ(props: Props) {
  return (
    <Box component="header" className={classes.header}>
      <Box
        shrink
        hAlignContent="left"
        vAlignContent="center"
        onClick={pipe(props.onLogoClick, option.toUndefined)}
      >
        <Logo height={24} />
        <Space units={4} />
        <LogoOpen height={24} />
      </Box>
    </Box>
  );
}
