import {
  DropdownField as DSDropdownField,
  DropdownFieldProps as DSDropdownFieldProps,
  Omit,
} from "design-system";
import { useFormatMessage } from "../../intl";

export type DropdownFieldProps<T> = Omit<
  DSDropdownFieldProps<T, false>,
  | "cancelLabel"
  | "noResultsLabel"
  | "clearSelectionLabel"
  | "filterInputPlaceholder"
  | "ctaLabel"
>;

export function DropdownField<T>(props: DropdownFieldProps<T>) {
  const formatMessage = useFormatMessage();
  return (
    <DSDropdownField
      {...props}
      cancelLabel={formatMessage("Cancel")}
      noResultsLabel={formatMessage("NoResultsFound")}
      clearSelectionLabel={formatMessage("Dropdown.clearSelection")}
      filterInputPlaceholder={formatMessage("Dropdown.filterInputPlaceholder")}
      ctaLabel={formatMessage("Confirm.buttonLabel")}
      isMulti={false}
    />
  );
}
