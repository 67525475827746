import { ComputedFieldProps } from "design-system";
import { eq, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useEffect } from "react";

export const usePropagateHasChanged: <T>(props: {
  enabled?: boolean;
  initialValue: T;
  fieldProps: ComputedFieldProps<T>;
  equality: eq.Eq<T>;
  onHasChanged?: (hasChanged: boolean) => unknown;
}) => void = ({
  enabled = true,
  initialValue,
  fieldProps,
  equality,
  onHasChanged,
}) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    pipe(
      onHasChanged,
      option.fromNullable,
      option.map(callback =>
        callback(!equality.equals(initialValue, fieldProps.value))
      )
    );
  }, [fieldProps.value]);
};
