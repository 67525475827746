import {
  Box,
  useAuthenticationContext,
  unsafeLocalizedString,
} from "design-system";
import { useAppContext } from "../../useAppContext";
import * as classes from "./EnvBadge.treat";
import { useEffect, useState } from "react";
import { useLogger, useMessageEventLogs } from "./logger";
import { EnvInfoDialog } from "./EnvInfoDialog";
import { constVoid } from "fp-ts/function";

export function EnvBadge() {
  const {
    config,
    updateTranslations,
    setRemoteConfigOverride,
  } = useAppContext();
  const { authInfo } = useAuthenticationContext();
  const [infoVisible, setInfoVisible] = useState(false);

  function refreshTranslations(e: React.SyntheticEvent) {
    e.preventDefault();
    const disabled = toggleTranslations();
    updateTranslations();
    alert((disabled ? "Disable" : "Updating") + " translations");
  }

  function toggleTranslations() {
    const translationsKey = "translations";
    const params = new URLSearchParams(window.location.search);
    if (params.has(translationsKey)) {
      params.delete(translationsKey);
    } else {
      params.set(translationsKey, "false");
    }
    var newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      (params.toString() ? "?" + params.toString() : "");
    window.history.replaceState({ path: newUrl }, "", newUrl);
    return params.has(translationsKey);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      (window as any).setRemoteConfigOverride = setRemoteConfigOverride;
      if (config.environment !== "prod") {
        console.info("Configuration settings", config);
      }
    }
  }, [config.environment]);

  useLogger(true);

  // log received events to debug SBL-43948
  useMessageEventLogs(config.windowMessageEventsLogs);

  switch (config.environment) {
    case "dev":
    case "sit":
    case "uat":
    case "hf":
    case "ci":
      return (
        <Box
          className={classes.envBadge}
          onClick={() => {
            if (!infoVisible) {
              setInfoVisible(true);
            }
          }}
          onContextMenu={!infoVisible ? refreshTranslations : constVoid}
        >
          {unsafeLocalizedString(config.environment)}
          {infoVisible && (
            <EnvInfoDialog
              authInfo={authInfo}
              onDismiss={() => setInfoVisible(false)}
            />
          )}
        </Box>
      );
    case "prod":
      return null;
  }
}
