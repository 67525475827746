import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/optionFromNullable";
import { apiCall } from "../APICall";

import {
  LinkGenerationError,
  OtpGenerationError,
  OtpVerifyInput,
  OtpGenerationInput,
  OTPGenerationOutput,
  OtpVerifyError,
  EmailVerificationOutput,
} from "../PhoneAndEmailVerification/api";
import {
  GenericError,
  CompressedFileContent,
  withCoApplicant,
} from "../globalDomain";
import {
  UserStatus,
  CarOwnershipType,
  HousingType,
  OtherDocumentType,
  PersonalProfile,
  IncomeSourceExtended,
} from "./domain";
import {
  IDAddressSubmitInput,
  eqIDAddressSubmitInput,
  PermanentAddressSubmitInput,
  eqPermanentAddressSubmitInput,
} from "../IdUpload/api";
import { AddressType, eqAddressType } from "../IdUpload/domain";
import {
  NonNegative,
  NonNegativeInteger,
  LocalizedString,
} from "design-system";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

const MarketingConsentUpdateInput = t.type({
  marketingConsent: t.boolean,
});

export type MarketingConsentUpdateInput = t.TypeOf<
  typeof MarketingConsentUpdateInput
>;

export const eqMarketingConsentUpdateInput = eq.getStructEq({
  marketingConsent: eq.eqBoolean,
});

export const CheckStatus = t.keyof({
  PENDING: true,
  DONE: true,
  ERROR: true,
});

const UpdateEmailInput = t.type({
  emailAddress: t.string,
});
export type UpdateEmailInput = t.TypeOf<typeof UpdateEmailInput>;

const UpdateUserIdInput = t.type({
  newAlias: t.string,
});
const UpdatePasswordForCommunicationInput = t.type({
  passwordForCommunication: t.string,
});
const UpdateUserStatusInput = t.type({
  newStatus: UserStatus,
});
const UpdateBiometricConsentInput = t.type({
  biometricConsent: t.boolean,
});

const UpdateAdditionalInformationInput = t.type({
  companyName: optionFromNullable(NonEmptyString),
  companyIco: optionFromNullable(NonEmptyString),
  monthlyIncome: optionFromNullable(NonNegative),
  sourceOfIncome: optionFromNullable(IncomeSourceExtended),
  housingType: optionFromNullable(HousingType),
  carOwnership: optionFromNullable(CarOwnershipType),
  sourceOfIncomeLabelNew: optionFromNullable(LocalizedString),
  sourceOfIncomeLabelOld: optionFromNullable(LocalizedString),
  housingTypeLabelNew: optionFromNullable(LocalizedString),
  housingTypeLabelOld: optionFromNullable(LocalizedString),
  carOwnershipLabelNew: optionFromNullable(LocalizedString),
  carOwnershipLabelOld: optionFromNullable(LocalizedString),
});
export type UpdateAdditionalInformationInput = t.TypeOf<
  typeof UpdateAdditionalInformationInput
>;

const ExistingClientPrivateCheck = t.type({
  privateClient: t.boolean,
});
export type ExistingClientPrivateCheck = t.TypeOf<
  typeof ExistingClientPrivateCheck
>;

export const checkPrivateClient = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "checkPrivateClient"],
  inputCodec: t.void,
  outputCodec: ExistingClientPrivateCheck,
  errorCodec: GenericError,
});

export const personalProfileRead = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "personalProfile"],
  outputCodec: PersonalProfile,
  errorCodec: GenericError,
});

export const getPersonalProfile = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "personalProfile", "get"],
  outputCodec: PersonalProfile,
  errorCodec: GenericError,
});

export const updateMarketingConsent = apiCall({
  inputCodec: MarketingConsentUpdateInput,
  inputEq: eqMarketingConsentUpdateInput,
  path: ["clients", "personalProfile", "marketingConsent"],
});

export const updatePhoneNumber = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "phoneNumber"],
  inputCodec: OtpGenerationInput,
  errorCodec: GenericError,
});

const LinkGenerationOutput = t.type({
  success: t.boolean,
  attemptsLeft: optionFromNullable(NonNegativeInteger),
});

export const updateEmailAndLinkGenerationNow = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "email", "now"],
  inputCodec: UpdateEmailInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const updateEmailAndLinkGenerationLater = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "email", "later"],
  inputCodec: UpdateEmailInput,
  outputCodec: LinkGenerationOutput,
  errorCodec: LinkGenerationError,
});

export const generateOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "authorization", "otp"],
  inputCodec: OtpGenerationInput,
  outputCodec: OTPGenerationOutput,
  errorCodec: OtpGenerationError,
});

export const verifyOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "authorization", "otp", "verify"],
  inputCodec: OtpVerifyInput,
  errorCodec: OtpVerifyError,
});

export const verifyEsawOTP = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "authorization", "esaw", "verify"],
  inputCodec: OtpVerifyInput,
  errorCodec: OtpVerifyError,
});

export const updateUserId = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "userId"],
  inputCodec: UpdateUserIdInput,
  errorCodec: GenericError,
});

export const updatePasswordForCommunication = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "passwordForCommunication"],
  inputCodec: UpdatePasswordForCommunicationInput,
  errorCodec: GenericError,
});

export const updateUserStatus = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "userStatus"],
  inputCodec: UpdateUserStatusInput,
  errorCodec: GenericError,
});

export const updateBiometricConsent = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "biometricConsent"],
  inputCodec: UpdateBiometricConsentInput,
  errorCodec: GenericError,
});

export const results = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["clients", "personalProfile", "results"],
  outputCodec: t.type({
    id: optionFromNullable(t.string),
    status: CheckStatus,
  }),
});

export const createAdditionalAddress = apiCall({
  path: ["clients", "personalProfile", "additionalAddress"],
  inputCodec: IDAddressSubmitInput,
  inputEq: eqIDAddressSubmitInput,
});

export const savePermanentAddress = apiCall({
  path: ["clients", "personalProfile", "permanentAddress"],
  inputCodec: PermanentAddressSubmitInput,
  inputEq: eqPermanentAddressSubmitInput,
});

export const updateAdditionalAddress = apiCall({
  path: ["clients", "personalProfile", "additionalAddress", "update"],
  inputCodec: IDAddressSubmitInput,
  inputEq: eqIDAddressSubmitInput,
});

const RemoveAdditionalAddressInput = t.type({
  addressType: AddressType,
});
const eqAdditionalAddressInput = eq.getStructEq({
  addressType: eqAddressType,
});

export const removeAdditionalAddress = apiCall({
  path: ["clients", "personalProfile", "additionalAddress", "delete"],
  inputCodec: RemoveAdditionalAddressInput,
  inputEq: eqAdditionalAddressInput,
});

export const updateAdditionalInformation = apiCall({
  path: ["clients", "personalProfile", "additionalInformation"],
  inputCodec: UpdateAdditionalInformationInput,
  inputEq: eq.fromEquals(constFalse),
});

export const resetSecurityPin = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["clients", "personalProfile", "otp", "pinReset"],
  errorCodec: GenericError,
});

export const uploadPrimaryIdDocument = apiCall({
  path: ["clients", "personalProfile", "primaryIdDocument"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const uploadSecondaryIdDocument = apiCall({
  path: ["clients", "personalProfile", "secondaryIdDocument"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

const UploadAdditionalDocumentInput = t.type({
  fileContent: CompressedFileContent,
  documentType: OtherDocumentType,
});

type UploadAdditionalDocumentInput = t.TypeOf<
  typeof UploadAdditionalDocumentInput
>;

export const uploadAdditionalDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "uploadAdditionalClientDocuments"],
  inputCodec: UploadAdditionalDocumentInput,
});

export const ClientProfileSectionName = t.keyof({
  PhoneNumber: true,
  EmailAddress: true,
  PermanentAddress: true,
  CurrentAdditionAddress: true,
  ContactAdditionalAddress: true,
  AdditionalInformation: true,
  BiometricConsent: true,
  UserStatus: true,
  UserId: true,
  PasswordForCommunication: true,
  MarketingConsent: true,
  SecondaryIdDocument: true,
  PrimaryIdDocument: true,
  AdditionalDocuments: true,
  SecurityPIN: true,
});
export type ClientProfileSectionName = t.TypeOf<
  typeof ClientProfileSectionName
>;

const SaveProcessInfoInput = t.type({
  sectionName: ClientProfileSectionName,
  clientNumber: t.string,
});

export const saveProcessInfo = apiCall({
  path: ["clients", "personalProfile", "saveProcessStep"],
  inputEq: eq.fromEquals(constFalse),
  inputCodec: SaveProcessInfoInput,
});

export const emailVerification = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["clients", "personalProfile", "checkEmailVerified"],
  inputCodec: withCoApplicant(t.type({})),
  outputCodec: EmailVerificationOutput,
});

export const clientLogout = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["authorization", "client", "logout"],
  inputCodec: t.void,
});
