import { Omit, useIsTouchScreen } from "design-system";
import { ComponentProps } from "react";
import { option } from "fp-ts";
import { constant, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { SnackbarProvider } from "../Snackbar/SnackbarProvider";
import { CookiesConsent } from "../CookiesConsent/CookiesConsent";
import { useIsSBChannel } from "../../useChannel";
import { MainLayoutCZ } from "../MainContent/MainLayoutCZ";

type Props = Omit<ComponentProps<typeof MainLayoutCZ>, "footer">;

export function MainLayout(props: Props) {
  const formatMessage = useFormatMessage();
  const { children, ...otherProps } = props;
  const isTouchScreen = useIsTouchScreen();
  const isSBChannel = useIsSBChannel();

  return (
    <MainLayoutCZ
      {...otherProps}
      footer={pipe(
        formatMessage("Footer"),
        option.fromPredicate(constant(!isTouchScreen))
      )}
      hideHeaderOnMobile={isSBChannel}
    >
      <CookiesConsent />
      <SnackbarProvider>{children}</SnackbarProvider>
    </MainLayoutCZ>
  );
}
