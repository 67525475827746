import { Reducer, useReducer, Dispatch, useState } from "react";
import { either, option } from "fp-ts";
import { constant, constNull, pipe } from "fp-ts/function";
import { useEvent, useInterval } from "react-use";
import { useBranchExperienceContext } from "./BranchExperienceContext";
import {
  DispatchedActionMessage,
  dispatchedActionMessage,
  InitializedMessage,
  InitRequestMessage,
  InitResponseMessage,
  MessageToChild,
  MessageToParent,
  ReducerId,
  reducerId,
} from "./sharedReducerDomain";
import * as t from "io-ts";
import { Option } from "fp-ts/Option";

type InitAction<S extends t.Mixed> = {
  type: "InitState";
  payload: t.TypeOf<S>;
};
type InternalAction<S extends t.Mixed, A extends t.Mixed> =
  | t.TypeOf<A>
  | InitAction<S>;

const isInitAction = <S extends t.Mixed, A extends t.Mixed>(
  action: InternalAction<S, A>
): action is InitAction<S> => {
  return "type" in action && action.type === "InitState";
};

type BaseReducer<S extends t.Mixed, A extends t.Mixed> = Reducer<
  t.TypeOf<S>,
  t.TypeOf<A>
>;
type SharedReducerConfig<S extends t.Mixed, A extends t.Mixed> = {
  reducerId: ReducerId;
  stateCodec: S;
  actionCodec: A;
  reducer: BaseReducer<S, A>;
};
export const sharedReducerConfig = <S extends t.Mixed, A extends t.Mixed>(
  id: string,
  stateCodec: S,
  actionCodec: A,
  reducer: BaseReducer<S, A>
): SharedReducerConfig<S, A> => {
  return {
    reducerId: reducerId(id),
    stateCodec,
    actionCodec,
    reducer,
  };
};

type SharedState<S extends t.Mixed> = Option<t.TypeOf<S>>;

type SharedReducer<S extends t.Mixed, A extends t.Mixed> = Reducer<
  SharedState<S>,
  InternalAction<S, A>
>;

function reducerWithInit<S extends t.Mixed, A extends t.Mixed>(
  baseReducer: BaseReducer<S, A>
): SharedReducer<S, A> {
  return (state, action) => {
    if (isInitAction(action)) {
      return option.some(action.payload);
    } else {
      return pipe(
        state,
        option.map(currentState => baseReducer(currentState, action))
      );
    }
  };
}

function useSharedDispatch<S extends t.Mixed, A extends t.Mixed>(
  reducerId: ReducerId,
  actionCodec: A,
  localDispatch: Dispatch<InternalAction<S, A>>
): Dispatch<InternalAction<S, A>> {
  const { postMessage } = useBranchExperienceContext();
  return action => {
    localDispatch(action);
    postMessage(
      DispatchedActionMessage(actionCodec).encode(
        dispatchedActionMessage(reducerId, action)
      )
    );
  };
}

export function useParentSharedReducer<S extends t.Mixed, A extends t.Mixed>(
  reducerConfig: SharedReducerConfig<S, A>,
  initialState: t.TypeOf<S>
): [t.TypeOf<S>, Dispatch<InternalAction<S, A>>, boolean] {
  const { stateCodec, actionCodec, reducer, reducerId } = reducerConfig;
  const { postMessage } = useBranchExperienceContext();
  const internalReducer = reducerWithInit(reducer);
  const [state, dispatch] = useReducer(
    internalReducer,
    option.some(initialState)
  );
  const sharedDispatch = useSharedDispatch(reducerId, actionCodec, dispatch);
  const [isInitializing, setIsInitializing] = useState(true);

  useEvent("message", (message: MessageEvent) => {
    pipe(
      message.data,
      MessageToParent(actionCodec).decode,
      either.map(message => {
        if (message.reducerId !== reducerId) return;
        switch (message.type) {
          case "Init":
            setIsInitializing(true);
            postMessage(
              InitResponseMessage(stateCodec).encode({
                type: "Init",
                reducerId,
                initialState: pipe(
                  state,
                  option.getOrElse(() => initialState)
                ),
              })
            );
            break;
          case "Initialized":
            setIsInitializing(false);
            break;
          case "ActionDispatched":
            dispatch(message.action);
            break;
        }
      })
    );
  });

  return [
    pipe(state, option.getOrElse(initialState)),
    sharedDispatch,
    isInitializing,
  ];
}

export function useChildSharedReducer<S extends t.Mixed, A extends t.Mixed>(
  reducerConfig: SharedReducerConfig<S, A>
): [SharedState<S>, Dispatch<InternalAction<S, A>>] {
  const { stateCodec, actionCodec, reducer, reducerId } = reducerConfig;
  const internalReducer = reducerWithInit(reducer);
  const [state, dispatch] = useReducer(internalReducer, option.none);
  const sharedDispatch = useSharedDispatch(reducerId, actionCodec, dispatch);
  const { postMessage } = useBranchExperienceContext();

  useEvent("message", (message: MessageEvent) => {
    pipe(
      message.data,
      MessageToChild(stateCodec, actionCodec).decode,
      either.map(message => {
        if (message.reducerId !== reducerId) return;
        switch (message.type) {
          case "Init":
            if (option.isNone(state)) {
              dispatch({
                type: "InitState",
                payload: message.initialState,
              });
              postMessage(
                InitializedMessage.encode({ type: "Initialized", reducerId })
              );
            }
            break;
          case "ActionDispatched":
            dispatch(message.action);
            break;
        }
      })
    );
  });

  useInterval(() => {
    if (option.isNone(state)) {
      postMessage(InitRequestMessage.encode({ type: "Init", reducerId }));
    }
  }, pipe(state, option.fold(constant(50), constNull)));

  return [state, sharedDispatch];
}
