import { createContext } from "react";
import { Option } from "fp-ts/Option";
import { APIParameters, SupportedLocales } from "./globalDomain";
import { RemoteConfig } from "./ConfigBlocks";
import { option } from "fp-ts";

export interface AppContext {
  config: RemoteConfig;
  apiParameters: APIParameters;
  locale: SupportedLocales;
  buildVersion: string;
  setLocale: (locale: SupportedLocales) => void;
  updateTranslations: () => unknown;
  updateRemoteConfig: () => unknown;
  setRemoteConfigOverride: (override: Partial<RemoteConfig>) => unknown;
}

export const AppContext = createContext<Option<AppContext>>(option.none);
