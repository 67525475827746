import { Tenant } from "../../globalDomain";
import czFlag from "./cz.svg";
import skFlag from "./sk.svg";
import * as classes from "./CountryFlag.treat";

const countryIcons: { [K in Tenant]: string } = {
  CZ: czFlag,
  SK: skFlag,
};

type Props = {
  tenant: Tenant;
};

export function CountryFlag(props: Props) {
  return (
    <img
      alt={props.tenant}
      src={countryIcons[props.tenant]}
      className={classes.flag}
    />
  );
}
