import { ComponentProps } from "react";
import {
  containerRowMaxWidth,
  palette,
  spaceUnit,
} from "design-system/lib/styleConstants";
import { HeaderCZ } from "./HeaderCZ";
import { Logo } from "./Logo";
import * as classes from "./HeaderTouchScreen.treat";
import { option } from "fp-ts";
import { constNull, pipe } from "fp-ts/function";
import { Space, Box, Heading } from "design-system";
import { LogoOpen } from "./LogoOpen";

type Props = Pick<ComponentProps<typeof HeaderCZ>, "title">;

export function HeaderTouchScreen(props: Props) {
  return (
    <Box component="header" height={112} className={classes.header}>
      <Box grow shrink />
      <Box
        style={{
          width: "100%",
          maxWidth: containerRowMaxWidth,
          justifyContent: "space-around",
          margin: "0 auto",
        }}
      >
        <Box
          shrink
          hAlignContent="left"
          vAlignContent="center"
          className={classes.logoArea}
        >
          <Logo height={36} />
          <Space units={4} />
          <LogoOpen height={36} />
        </Box>
        <Box
          grow
          hAlignContent="left"
          vAlignContent="center"
          style={{
            backgroundColor: palette.red800,
            paddingLeft: 10 * spaceUnit,
          }}
        >
          {pipe(
            props.title,
            option.fold(constNull, title => (
              <Heading size="medium" weight="medium" color={palette.white}>
                {title}
              </Heading>
            ))
          )}
        </Box>
      </Box>
      <Box grow shrink style={{ backgroundColor: palette.red800 }} />
    </Box>
  );
}
