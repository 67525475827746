import { useEffect, useRef, useState } from "react";
import { useBranchExperienceContext } from "./BranchExperienceContext";

type Props = {
  children: JSX.Element;
  disabled: boolean;
};

export function BranchExperienceParentSwitch({ children, disabled }: Props) {
  const context = useBranchExperienceContext();
  const [loading, setLoading] = useState(true);
  const refPrev = useRef<boolean>();

  useEffect(() => {
    if (context) {
      context.setTempDisabled(prev => {
        refPrev.current = prev;
        return disabled;
      });
    }
    setLoading(false);
    return () => {
      if (context && refPrev.current !== undefined) {
        context.setTempDisabled(refPrev.current);
      }
    };
  }, []);

  if (loading) {
    return <></>;
  }

  return <>{children}</>;
}
