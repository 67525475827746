import { option } from "fp-ts";
import {
  Action,
  Banner,
  Body,
  Box,
  Button,
  convertToBlob,
  Dialog,
  downloadFile,
  DownloadIcon,
  ErrorBanner,
  FileIcon,
  Loader,
  LocalizedString,
  Space,
  Sticky,
  useDialogDimensionLimits,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
import * as classes from "./FilePDFDialog.treat";
import { useFormatMessage } from "../../../intl";
import { TaskEither } from "fp-ts/TaskEither";
import { useRemoteData } from "../../../useRemoteData";
import * as remoteData from "../../../RemoteData";
import { pipe, constant, constFalse, constVoid } from "fp-ts/function";
import { DocumentContentOutput, DocumentMeta } from "../../documentAPI";
import { useMemo, useState } from "react";
import { DocumentReviewDialogChild } from "../DocumentReviewDialog/DocumentReviewDialogChild";
import {
  DocumentType,
  DocumentViewer,
} from "../../DocumentViewer/DocumentViewer";
import { spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  title: LocalizedString;
  subtitle?: LocalizedString;
  onDismiss?: () => unknown;
  onConfirm?: {
    label: LocalizedString;
    action: () => unknown;
  };
  file: TaskEither<unknown, DocumentContentOutput>;
  downloadAction?: TaskEither<unknown, string>;
  infoBannerContent?: LocalizedString;
  errorBannerContent?: LocalizedString;
  mustReadAllDocuments?: boolean;
  isForeignClient?: boolean;
  onForeignDocumentOpen?: (document: DocumentMeta) => unknown;
  document?: DocumentMeta;
};

export function FilePDFDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const isBranchExperienceTouchScreen = useIsTouchScreen();
  const isMobile = useIsMobileLayout();
  const file = useRemoteData(useMemo(() => props.file, []));
  const { heightLimits } = useDialogDimensionLimits();

  const { maxHeight } = heightLimits();

  const [hasReadAll, setHasReadAll] = useState<boolean>(
    !props.mustReadAllDocuments
  );

  const getDownloadUrl = (file: DocumentContentOutput) =>
    URL.createObjectURL(convertToBlob(file.content, "PDF"));

  const isFrameWorkAgreement = (document: DocumentMeta) =>
    pipe(
      document.docType,
      option.fold(constFalse, docType => docType === "FRAMEWORK_AGREEMENT")
    );

  const isProductContract = (document: DocumentMeta) =>
    pipe(
      document.docType,
      option.fold(constFalse, docType => docType === "PRODUCT_CONTRACT_ACCOUNT")
    );

  const actions = pipe(
    file,
    remoteData.fold<
      unknown,
      DocumentContentOutput,
      React.ComponentProps<typeof Dialog>["actions"]
    >(constant([]), constant([]), file => {
      const actions: Array<Action> = [];

      if (!isBranchExperienceTouchScreen) {
        actions.push({
          disabled: !hasReadAll,
          variant: "text" as const,
          label: formatMessage("Download.buttonLabel"),
          icon: DownloadIcon,
          action() {
            downloadFile(getDownloadUrl(file), file.filename);
          },
        });
      }

      if (props.onConfirm) {
        actions.push({
          disabled: !hasReadAll,
          variant: "primary",
          label: props.onConfirm.label || formatMessage("Confirm.buttonLabel"),
          action: props.onConfirm.action,
        });
      }

      return actions.slice(0, 2) as [] | [Action] | [Action, Action];
    })
  );

  const dialogContent = (
    <>
      {props.subtitle && (
        <Box hAlignContent="left" marginBottom={5}>
          <Body size="x-small" weight="regular">
            {props.subtitle}
          </Body>
        </Box>
      )}
      {pipe(
        file,
        remoteData.fold(
          () => (
            <Box
              hAlignContent="center"
              vAlignContent="center"
              className={classes.loadingWrapper}
            >
              <Loader />
            </Box>
          ),
          () => <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>,
          file => (
            <Box
              column
              grow
              style={{
                minHeight: isMobile
                  ? 0
                  : maxHeight
                  ? maxHeight - 60 * spaceUnit
                  : "70vh",
              }}
            >
              <DocumentViewer
                name={file.filename}
                type={DocumentType.Pdf}
                data={file.content}
                onLastPageReach={() => {
                  if (!hasReadAll && props.mustReadAllDocuments) {
                    setHasReadAll(true);
                  }
                }}
              />
              {props.infoBannerContent && (
                <>
                  <Space units={spaceUnit * 7} />
                  <Sticky bottom={spaceUnit}>
                    <Banner
                      type="informative"
                      content={props.infoBannerContent}
                      actions={option.none}
                      onDismiss={option.none}
                      title={option.none}
                      width="100%"
                    />
                    <Space units={spaceUnit} />
                  </Sticky>
                </>
              )}
              {props.errorBannerContent && (
                <>
                  <Space units={spaceUnit} />
                  <Sticky bottom={spaceUnit}>
                    <Banner
                      type="warning"
                      content={props.errorBannerContent}
                      actions={option.none}
                      onDismiss={option.none}
                      title={option.none}
                      width="100%"
                    />
                    <Space units={spaceUnit} />
                  </Sticky>
                </>
              )}
            </Box>
          )
        )
      )}
      {props.isForeignClient &&
        props.document &&
        (isFrameWorkAgreement(props.document) ||
          isProductContract(props.document)) && (
          <Box>
            <Space units={4} />
            <Button
              variant="text"
              label={formatMessage(
                "UKonto.ReadDocuments.foreignContractsLabel"
              )}
              icon={FileIcon}
              size="small"
              action={() =>
                props.onForeignDocumentOpen && props.document
                  ? props.onForeignDocumentOpen(props.document)
                  : constVoid
              }
            />
          </Box>
        )}
    </>
  );

  return (
    <>
      {isMobile ? (
        <Dialog
          variant="document"
          title={props.title}
          actions={actions}
          onDismiss={
            props.onDismiss ? option.some(props.onDismiss) : option.none
          }
        >
          {dialogContent}
        </Dialog>
      ) : (
        <Dialog
          variant="left"
          size={"medium"}
          title={props.title}
          actions={actions}
          onDismiss={
            props.onDismiss ? option.some(props.onDismiss) : option.none
          }
        >
          {dialogContent}
        </Dialog>
      )}
      {isBranchExperienceTouchScreen && <DocumentReviewDialogChild />}
    </>
  );
}
