import * as t from "io-ts";

export type ReducerIdBrand = string & {
  readonly ReducerId: unique symbol;
};
export const ReducerId = t.brand(
  t.string,
  (_): _ is t.Branded<string, ReducerIdBrand> => true,
  "ReducerId"
);
export type ReducerId = t.TypeOf<typeof ReducerId>;

export const InitRequestMessage = t.type({
  type: t.literal("Init"),
  reducerId: ReducerId,
});

export const InitResponseMessage = <S extends t.Mixed>(stateCodec: S) =>
  t.type({
    type: t.literal("Init"),
    reducerId: ReducerId,
    initialState: stateCodec,
  });

export const InitializedMessage = t.type({
  type: t.literal("Initialized"),
  reducerId: ReducerId,
});

export const DispatchedActionMessage = <A extends t.Mixed>(actionCodec: A) =>
  t.type({
    type: t.literal("ActionDispatched"),
    reducerId: ReducerId,
    action: actionCodec,
  });

export const MessageToParent = <A extends t.Mixed>(actionCodec: A) =>
  t.union([
    InitRequestMessage,
    InitializedMessage,
    DispatchedActionMessage(actionCodec),
  ]);

export const MessageToChild = <S extends t.Mixed, A extends t.Mixed>(
  stateCodec: S,
  actionCodec: A
) =>
  t.union([
    InitResponseMessage(stateCodec),
    DispatchedActionMessage(actionCodec),
  ]);

export const dispatchedActionMessage = <A extends t.Mixed>(
  reducerId: ReducerId,
  action: t.OutputOf<A>
) => ({
  type: "ActionDispatched" as const,
  reducerId,
  action,
});

export const reducerId = (id: string): ReducerId => {
  return id as ReducerId;
};
