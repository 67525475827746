import {
  AngleLeftIcon,
  Box,
  Button,
  ContentRow,
  ErrorBanner,
  Space,
  useIsMobileLayout,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import { EmailVerification } from "../PhoneAndEmailVerification/EmailVerification/EmailVerification";
import {
  EmailVerification as EmailVerificationType,
  EmailVerificationError,
} from "../PhoneAndEmailVerification/EmailVerification/state";
import { PhoneVerification } from "../PhoneAndEmailVerification/PhoneVerification/PhoneVerification";
import * as api from "./api";
import * as phoneAndEmailVerificationApi from "./../PhoneAndEmailVerification/api";
import {
  OtpGenerationError,
  OtpGenerationInput,
} from "./../PhoneAndEmailVerification/api";
import { ContactChangeDataStatus } from "./ClientProfileState";
import { pipe } from "fp-ts/function";
import { useScrollTopOnChange } from "../Common/useScrollTopOnChange";
import { PageHeading } from "../Common/PageHeading/PageHeading";
import { useIsInPersonChannel } from "../useChannel";
import { useAppContext } from "../useAppContext";

type Props = {
  data: ContactChangeDataStatus;
  onExit: () => unknown;
  onComplete: () => unknown;
  onHasChanged?: (hasChanged: boolean) => unknown;
  onMaxNumberChangesReached?: (error: OtpGenerationError) => unknown;
};

export function ContactChange(props: Props) {
  useScrollTopOnChange("auto");
  const formatMessage = useFormatMessage();

  const {
    apiParameters: { channel },
  } = useAppContext();

  const updatePhoneNumber = useCommand(api.updatePhoneNumber);

  const generateOTP = useCommand(api.generateOTP);
  const verifyOTP = useCommand(api.verifyOTP);

  const startUpdateEmailProcess = useCommand(
    phoneAndEmailVerificationApi.startProcess
  );
  const generateLinkNow = useCommand(api.updateEmailAndLinkGenerationNow);
  const generateLinkLater = useCommand(api.updateEmailAndLinkGenerationLater);

  const updatePhoneNumberAndGenerateOTP = (data: OtpGenerationInput) =>
    pipe(
      updatePhoneNumber(data),
      taskEither.chain(_ => generateOTP(data))
    );
  const isInPerson = useIsInPersonChannel();

  const skipVerification = isInPerson;

  const handleRequestLink = (
    emailAddress: string
  ): TaskEither<EmailVerificationError, EmailVerificationType> =>
    pipe(
      { emailAddress },
      skipVerification ? generateLinkLater : generateLinkNow,
      taskEither.bimap(
        error => error.id,
        ({ attemptsLeft }) => ({
          type: skipVerification ? "SkipVerification" : "VerifyNow",
          attemptsLeft,
        })
      )
    );

  const isMobileLayout = useIsMobileLayout();

  const content = (
    <Box column grow shrink>
      <Space units={10} />
      {isMobileLayout && (
        <ContentRow type="lateral-margins">
          <Box hAlignContent="left">
            <Button
              variant="text"
              size="default"
              label={formatMessage("PreviousStep")}
              action={props.onExit}
              icon={AngleLeftIcon}
            />
          </Box>
        </ContentRow>
      )}
      <PageHeading
        title={
          props.data.contactType === "phoneNumber"
            ? formatMessage("ClientProfile.contactFlow.verifyTitle.phoneNumber")
            : formatMessage("ClientProfile.contactFlow.verifyTitle.email")
        }
        description={
          props.data.contactType === "phoneNumber"
            ? formatMessage(
                "ClientProfile.contactFlow.phoneNumber.securityAlert"
              )
            : formatMessage("ClientProfile.contactFlow.email.securityAlert")
        }
      />
      <ContentRow type="lateral-margins">
        <Box column shrink grow>
          {(() => {
            switch (props.data.contactType) {
              case "phoneNumber":
                if (channel !== "OB_Remote") {
                  const initialPhoneNumber =
                    props.data.operationType === "edit"
                      ? props.data.value
                      : option.some(props.data.value);
                  return (
                    <PhoneVerification
                      heading={formatMessage(
                        "Identification.otp.verifyPhoneNumber"
                      )}
                      contactDetailsDuplicityCheck={false}
                      initialPhoneNumber={initialPhoneNumber}
                      canEdit={props.data.operationType === "edit"}
                      coApplicant={option.none}
                      onPhoneVerified={option.none}
                      onComplete={props.onComplete}
                      generateOTP={updatePhoneNumberAndGenerateOTP}
                      verifyOTP={verifyOTP}
                      layout="standalone"
                      onHasChanged={props.onHasChanged}
                      onMaxNumberChangesReached={
                        props.onMaxNumberChangesReached
                      }
                    />
                  );
                } else
                  return (
                    <ErrorBanner children={formatMessage("GenericError")} />
                  );
              case "email":
                return (
                  <EmailVerification
                    coApplicant={option.none}
                    initialEmail={
                      props.data.operationType === "edit"
                        ? props.data.value
                        : option.some(props.data.value)
                    }
                    initialPromoCode={option.none}
                    canEdit={props.data.operationType === "edit"}
                    onRequestLink={(_, emailAddress) =>
                      pipe(
                        startUpdateEmailProcess({
                          processName: "emailVerification",
                        }),
                        taskEither.mapLeft<unknown, EmailVerificationError>(
                          () => "GenericError"
                        ),
                        taskEither.chain(() => handleRequestLink(emailAddress))
                      )
                    }
                    onComplete={props.onComplete}
                    layout="standalone"
                    heading={formatMessage("Identification.email.verifyEmail")}
                    verificationAPI={api.emailVerification}
                    onHasChanged={props.onHasChanged}
                  />
                );
            }
          })()}
          {!isMobileLayout && (
            <>
              <Space units={10} />
              <Box hAlignContent="left">
                <Button
                  variant="text"
                  size="default"
                  label={formatMessage("Back")}
                  action={props.onExit}
                  icon={AngleLeftIcon}
                />
              </Box>
            </>
          )}
        </Box>
      </ContentRow>
    </Box>
  );

  return content;
}
