import * as t from "io-ts";
import { sharedReducerConfig } from "../../../BranchExperience/useSharedReducer";

const Initial = t.type(
  {
    open: t.boolean,
  },
  "Initial"
);

const State = Initial;
export type State = t.TypeOf<typeof State>;

const OpenAction = t.type({
  type: t.literal("OpenAction"),
});
const CloseAction = t.type({
  type: t.literal("CloseAction"),
});
const Action = t.union([OpenAction, CloseAction]);
export type Action = t.TypeOf<typeof Action>;

export const onOpenAction: Action = {
  type: "OpenAction",
};
export const onCloseAction: Action = {
  type: "CloseAction",
};

function reducer(_: State, action: Action): State {
  switch (action.type) {
    case "OpenAction":
      return { open: true };
    case "CloseAction":
      return { open: false };
  }
}

export const reducerConfig = sharedReducerConfig(
  "FilePDFDialog",
  State,
  Action,
  reducer
);
