import { InfoIcon, Tooltip, LocalizedString, IconSize } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import * as classes from "./InfoTooltip.treat";

type Props = {
  content: LocalizedString;
  size?: IconSize;
  inline?: boolean;
};

export const InfoTooltip = (props: Props) => (
  <Tooltip
    content={props.content}
    inline={pipe(
      props.inline,
      option.fromNullable,
      option.getOrElseW(() => true)
    )}
  >
    <InfoIcon
      size={pipe(
        props.size,
        option.fromNullable,
        option.getOrElse(() => "default" as IconSize)
      )}
      className={classes.infoIcon}
    />
  </Tooltip>
);
