import {
  Box,
  Children,
  ErrorBanner,
  FileContent,
  Loader,
  PdfViewer as InnerPdfViewer,
  ValueOf,
} from "design-system";
import { useEffect, useState } from "react";
import { useFormatMessage } from "../../intl";
import * as Styles from "./DocumentViewer.treat";
// eslint-disable-next-line no-restricted-imports
import "design-system/lib/pdf_viewer.css";

export const DocumentType = {
  Pdf: "application/pdf",
  // Image.
  Jpeg: "image/jpeg",
  Png: "image/png",
  Tiff: "image/tiff",
  // Office.
  Docx:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  Xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // Outlook.
  Msg: "application/vnd.ms-outlook",
  Msg2: ".msg",
} as const;

export type DocumentType = ValueOf<typeof DocumentType>;

export type DocumentModel = {
  name: string;
  data: FileContent;
};

export type DocumentEnvelopeModel = DocumentModel & {
  type: DocumentType;
};

export type DocumentViewerProps = DocumentEnvelopeModel & {
  fallback?: (props: DocumentEnvelopeModel) => Children;
  onSupportDetected?: (supported: boolean) => void;
  onContentLoaded?: (numPages?: number) => unknown;
  onLastPageReach?: () => void;
};

export function DocumentViewer(props: DocumentViewerProps) {
  const { type, data, fallback, onSupportDetected, ...otherProps } = props;
  const [isTypeSupported, setIsTypeSupported] = useState(true);

  useEffect(() => {
    // By default we assume a media type is supported. In case of error there
    // will be an handler which overwrites this assumption.
    // For example, TIFF image format is supported by IE and Edge Legacy on
    // Windows, and by Safari on MacOS, but not by other browsers.
    setIsTypeSupported(true);
  }, [type, data]);

  useEffect(() => {
    if (!onSupportDetected) {
      return;
    }

    onSupportDetected(isTypeSupported);
  }, [isTypeSupported, onSupportDetected]);

  useEffect(() => {
    if (isTypeSupported) {
      return;
    }

    logMediaUnsupported(type);
  }, [isTypeSupported, type]);

  if (!isTypeSupported) {
    return <>{fallback && fallback({ ...otherProps, type, data })}</>;
  }

  switch (type) {
    case DocumentType.Pdf: {
      return (
        <PdfViewer
          {...otherProps}
          data={data}
          onError={() => setIsTypeSupported(false)}
        />
      );
    }
    case DocumentType.Jpeg:
    case DocumentType.Png:
    case DocumentType.Tiff: {
      return (
        <ImageViewer
          {...otherProps}
          type={type}
          data={data}
          onError={() => setIsTypeSupported(false)}
          onLoad={() => setIsTypeSupported(true)}
        />
      );
    }
  }

  setIsTypeSupported(false);

  return null;
}

export type PdfViewerProps = DocumentModel & {
  onError?: () => void;
  onContentLoaded?: (numPages?: number) => unknown;
  onLastPageReach?: () => void;
};

export function PdfViewer(props: PdfViewerProps) {
  const [pdfLoading, setPdfLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [key, setkey] = useState(Math.random());

  const handleOnContentLoaded = (numPages?: number) => {
    setPdfLoading(false);
    props.onContentLoaded && props.onContentLoaded(numPages);
  };

  const handleOnError = () => {
    setPdfLoading(false);
    setHasError(true);
    props.onError && props.onError();
  };

  useEffect(() => {
    setPdfLoading(true);
    setHasError(false);
    setkey(Math.random());
  }, [props.data]);

  return (
    <Box grow style={{ position: "relative" }}>
      {pdfLoading && <DocumentLoading />}
      {!pdfLoading && hasError && <DocumentError />}
      <InnerPdfViewer
        data={props.data}
        onPdfLoaded={handleOnContentLoaded}
        onPdfError={handleOnError}
        onLastPageReach={props.onLastPageReach}
        workerSrc="/static/js/pdf.worker.min.js"
        key={key}
      />
    </Box>
  );
}

export type ImageViewerProps = DocumentEnvelopeModel & {
  onError?: () => void;
  onLoad?: () => void;
};

export function ImageViewer(props: ImageViewerProps) {
  const { type, data, onError, onLoad } = props;

  return (
    <img
      className={Styles.image}
      src={dataUrlFor(type, data)}
      onLoad={onLoad}
      onError={onError}
      alt={props.name}
    />
  );
}

export type DownloadDocumentButtonProps = DocumentEnvelopeModel;

export function DocumentLoading() {
  return (
    <Box hAlignContent="center" basis="100%" vAlignContent="center">
      <Loader />
    </Box>
  );
}

export function DocumentError() {
  const formatMessage = useFormatMessage();

  return (
    <ErrorBanner>{formatMessage("DocumentViewer.DisplayError")}</ErrorBanner>
  );
}

export function dataUrlFor(mimeType: string, data: string) {
  return `data:${mimeType};base64,` + data;
}

export function logMediaUnsupported(mimeType: string) {
  console.log(
    "DocumentViewer:",
    "browser seams to not support mimetype",
    mimeType
  );
}
