import {
  unsafeLocalizedString,
  Dialog,
  AuthenticationInfo,
  LocalizedString,
  InfoIcon,
} from "design-system";
import { option } from "fp-ts";
import * as api from "../../StandardLoan/api";
import { constNull, pipe } from "fp-ts/function";
import { useQuery } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import { Option } from "fp-ts/Option";
import { useEffect, useState } from "react";
import { useCopyToClipboard } from "react-use";

type Props = {
  authInfo: Option<AuthenticationInfo>;
  onDismiss: () => void;
};

export function EnvInfoDialog(props: Props) {
  const [getApplicationId] = useQuery(api.getApplicationId);
  const [, copyToClipboard] = useCopyToClipboard();

  const msgWithoutFlowId = "No Flow ID available";
  const msgWithFlowId = "Flow ID already copied to clipboard";
  const msgWithFlowIdAndAppId =
    "Flow ID and Application ID already copied to clipboard";

  const flowId = pipe(
    props.authInfo,
    option.fold(
      () => "",
      authInfo => authInfo.flowId
    )
  );

  const [appId, setAppId] = useState("");

  useEffect(() => {
    copyToClipboard(flowId + " " + appId);
  }, [appId]);

  return pipe(
    getApplicationId,
    remoteData.fold(
      constNull,
      () => (
        <Dialog
          variant="center"
          size="small"
          title={unsafeLocalizedString(
            flowId === "" ? msgWithoutFlowId : flowId + "\n" + msgWithFlowId
          )}
          onDismiss={option.of(props.onDismiss)}
          actions={[
            {
              variant: "primary",
              label: "OK" as LocalizedString,
              action: props.onDismiss,
            },
          ]}
          icon={InfoIcon}
        />
      ),
      applicationId =>
        pipe(
          applicationId.applicationId,
          option.fold(
            () => (
              <Dialog
                variant="center"
                size="small"
                title={unsafeLocalizedString(
                  flowId === ""
                    ? msgWithoutFlowId
                    : flowId + "\n" + msgWithFlowId
                )}
                onDismiss={option.of(props.onDismiss)}
                actions={[
                  {
                    variant: "primary",
                    label: "OK" as LocalizedString,
                    action: props.onDismiss,
                  },
                ]}
                icon={InfoIcon}
              />
            ),
            applicationId => {
              if (appId === "") {
                setAppId(applicationId);
              }
              return (
                <Dialog
                  variant="center"
                  size="small"
                  title={unsafeLocalizedString(
                    flowId === ""
                      ? msgWithoutFlowId
                      : flowId + "\n" + applicationId
                  )}
                  subtitle={unsafeLocalizedString(
                    flowId === "" ? "" : msgWithFlowIdAndAppId
                  )}
                  onDismiss={option.of(props.onDismiss)}
                  actions={[
                    {
                      variant: "primary",
                      label: "OK" as LocalizedString,
                      action: props.onDismiss,
                    },
                  ]}
                  icon={InfoIcon}
                />
              );
            }
          )
        )
    )
  );
}
