import { Box, Loader } from "design-system";

export function BasicFullScreenLoader() {
  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      vAlignContent="center"
      hAlignContent="center"
    >
      <Loader />
    </Box>
  );
}
