import {
  CookieBanner,
  buttonLink,
  Space,
  useIsMobileLayout,
} from "design-system";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { useAppContext } from "../../useAppContext";
import { IFrameDialog } from "../Dialogs/IFrameDialog";

const COOKIECONSENT_STORAGE_KEY = "cookieconsent";

function setPersistedCookieConsent() {
  try {
    localStorage.setItem(COOKIECONSENT_STORAGE_KEY, "true");
  } catch (e) {
    console.error("LocalStorage Error", e);
  }
}

function getPersistedCookieConsent(): boolean {
  try {
    return localStorage.getItem(COOKIECONSENT_STORAGE_KEY) === "true";
  } catch (e) {
    return false;
  }
}

type TriState = "hide" | "show" | "info";

export function CookiesConsent() {
  const formatMessage = useFormatMessage();
  const [status, setStatus] = useState<TriState>(
    getPersistedCookieConsent() ? "hide" : "show"
  );
  const appContext = useAppContext();
  const isMobileLayout = useIsMobileLayout();

  switch (status) {
    case "hide":
      return null;
    case "info":
      return (
        <IFrameDialog
          title={formatMessage("CookiesConsent.modal.title")}
          url={appContext.config.cookiesConsentInfoUrl}
          onDismiss={() => setStatus("show")}
        />
      );
    case "show":
      return (
        <>
          {isMobileLayout && <Space units={4} />}
          <CookieBanner
            infoText={[
              formatMessage("CookiesConsent.content"),
              buttonLink(
                () => setStatus("info"),
                formatMessage("CookiesConsent.moreInfo")
              ),
            ]}
            button={{
              label: formatMessage("CookiesConsent.action"),
              onDismiss: () => {
                setPersistedCookieConsent();
                setStatus("hide");
              },
            }}
          />
        </>
      );
  }
}
