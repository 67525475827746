import {
  Body,
  Box,
  Children,
  CloseIcon,
  Heading,
  LocalizedString,
  Space,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
import * as classes from "./HeaderCZ.treat";
import { Logo } from "./Logo";
import { Option } from "fp-ts/Option";
import { constNull, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { ActionIconProps, fold } from "./headerActionIcon";
import { MenuDropdown } from "../MenuDropdown/MenuDropdown";
import { HeaderMobileCZ } from "./HeaderMobile";
import { HeaderTouchScreen } from "./HeaderTouchScreen";
import cx from "classnames";
import { LogoOpen } from "./LogoOpen";
import { palette } from "design-system/lib/styleConstants";
import { ContainerRowA } from "./grid/ContainerRowA";
import { CommonProps } from "./CommonProps";

type Props = CommonProps & {
  title: Option<React.ComponentProps<typeof Heading>["children"]>;
  subtitle?: React.ComponentProps<typeof Body>["children"];
  onLogoClick: Option<() => unknown>;
  iconAction: Option<ActionIconProps>;
  dropdownHeading: Option<LocalizedString>;
  centerContent?: Children;
};

export function HeaderCZ(props: Props) {
  const isMobileLayout = useIsMobileLayout();
  const isTouchScreen = useIsTouchScreen();

  const renderCenterContent = () => {
    if (!props.centerContent) return <></>;
    return (
      <Box
        grow
        vAlignContent="center"
        hAlignContent="center"
        className={classes.centerContentArea}
      >
        {props.centerContent}
      </Box>
    );
  };

  if (isMobileLayout) {
    return <HeaderMobileCZ {...props} />;
  } else if (isTouchScreen) {
    return <HeaderTouchScreen {...props} />;
  } else {
    return (
      <ContainerRowA
        isHeader
        component="header"
        leftColor={palette.white}
        centerColor={palette.red800}
        rightColor={palette.red900}
        leftContent={
          <Box
            shrink
            hAlignContent="left"
            vAlignContent="center"
            className={cx(classes.logoArea, {
              [classes.logoAreaClickable]: pipe(
                props.onLogoClick,
                option.isSome
              ),
            })}
            onClick={pipe(props.onLogoClick, option.toUndefined)}
          >
            <Logo height={24} />
            <Space units={4} />
            <LogoOpen height={24} />
          </Box>
        }
        centerContent={renderCenterContent()}
        rightContent={
          <Box shrink vAlignContent="center" className={classes.productArea}>
            <Box column hAlignContent="right">
              {pipe(
                props.title,
                option.fold(constNull, title => (
                  <Heading size="small" weight="regular" color={palette.white}>
                    {title}
                  </Heading>
                ))
              )}
              {props.subtitle && (
                <Body size="small" weight="regular">
                  {props.subtitle}
                </Body>
              )}
            </Box>
            {pipe(
              props.iconAction,
              option.map(
                fold(
                  menuOptions => (
                    <MenuDropdown
                      heading={props.dropdownHeading}
                      options={menuOptions}
                    />
                  ),
                  onExit => (
                    <Box
                      className={classes.exit}
                      onClick={onExit}
                      data-test-id="exit_button"
                    >
                      <CloseIcon size="default" />
                    </Box>
                  )
                )
              ),
              option.map(actionIcon => (
                <>
                  <Space units={6} />
                  {actionIcon}
                </>
              )),
              option.toNullable
            )}
          </Box>
        }
      />
    );
  }
}
