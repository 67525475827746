import * as classes from "./Stepper.treat";
import cx from "classnames";
import * as zipper from "fp-ts-contrib/lib/Zipper";
import { Zipper } from "fp-ts-contrib/lib/Zipper";
import {
  Body,
  Box,
  CheckIcon,
  Space,
  Stack,
  LocalizedString,
  unsafeLocalizedString,
} from "design-system";
import { pipe } from "fp-ts/function";
import { forwardRef } from "react";
import { mapWithIndexAndOrdering } from "./Zipper";
import { CommonProps } from "./CommonProps";

type Props = CommonProps & {
  /**
   * The list of steps, divided in `lefts` (already "done" steps),
   * `focus` (the "current" step) and `rights` (steps still to do)
   */
  steps: Zipper<LocalizedString>;
  onStepClick: (index: number) => unknown;
  isStepClickable?: (index: number) => boolean;
};

type StepData = { onClick?: () => unknown } & (
  | { type: "done"; label: LocalizedString }
  | { type: "active" | "next"; number: number; label: LocalizedString }
);

function Step(props: StepData) {
  return (
    <Box
      vAlignContent="center"
      key={props.label}
      className={cx(classes.step, classes.stepVariant[props.type])}
      onClick={props.onClick}
      data-step-state={props.type}
    >
      <Box
        vAlignContent="center"
        hAlignContent="center"
        className={cx(
          classes.stepIndicator,
          classes.stepIndicatorVariant[props.type]
        )}
      >
        {props.type === "done" ? (
          <CheckIcon size="medium" />
        ) : (
          <Box>{unsafeLocalizedString(props.number)}</Box>
        )}
      </Box>
      <Box
        vAlignContent="center"
        className={cx(
          classes.stepLabel,
          classes.stepLabelCursor[
            props.type === "done" && props.onClick
              ? "clickable"
              : "nonClickable"
          ]
        )}
        grow
        shrink
      >
        <Space units={5} />
        <Body
          size="small"
          weight="regular"
          className={classes.stepLabelColor[props.type]}
        >
          {props.label}
        </Body>
      </Box>
    </Box>
  );
}

export const Stepper = forwardRef<HTMLElement, Props>((props, ref) => {
  const steps = pipe(
    props.steps,
    mapWithIndexAndOrdering((ordering, index, label) => {
      switch (ordering) {
        case -1:
          return (
            <Step
              type="done"
              label={label}
              key={index}
              onClick={
                props.isStepClickable != null && props.isStepClickable(index)
                  ? () => props.onStepClick(index)
                  : undefined
              }
            />
          );
        case 0:
          return (
            <Step
              type="active"
              label={label}
              key={index}
              number={index + 1}
              onClick={() => props.onStepClick(index)}
            />
          );
        case 1:
          return (
            <Step
              type="next"
              label={label}
              key={index}
              number={index + 1}
              onClick={() => props.onStepClick(index)}
            />
          );
      }
    }),
    zipper.toArray
  );

  return (
    <Stack
      column
      units={2}
      id={props.id}
      className={cx(classes.stepper, props.className)}
      width={props.width}
      ref={ref}
    >
      {steps}
    </Stack>
  );
});
