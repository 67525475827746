import { useEffect, useState, useCallback, useRef } from "react";

export function useCountdown(
  durationMs: number,
  onCountdownEnd: () => void,
  stopped: boolean = false
) {
  const end = useRef(Date.now() + durationMs);
  const [displayEnd, setDisplayEnd] = useState(Date.now() + durationMs);
  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (end.current > Date.now() && !stopped) {
      const timer = setTimeout(forceUpdate, 1000);
      return () => clearTimeout(timer);
    } else if (!stopped) {
      onCountdownEnd();
      return () => {};
    }

    return () => {};
  });

  const difference = Math.floor((displayEnd - Date.now()) / 1000);

  return [
    Math.max(0, difference),
    () => {
      const newEnd = Date.now() + durationMs;
      end.current = newEnd;
      setDisplayEnd(newEnd);
    },
  ] as const;
}
