import * as classes from "./Footer.treat";
import { constNull, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import {
  Body,
  ContentRow,
  Space,
  Stack,
  TextChildren,
  unsafeLocalizedString,
  useAuthenticationContext,
  useIsMobileLayout,
} from "design-system";

type Props = {
  children: TextChildren;
};

export function Footer(props: Props) {
  const { authInfo } = useAuthenticationContext();
  const isMobileLayout = useIsMobileLayout();
  return (
    <ContentRow grow={false} type={isMobileLayout ? "lateral-margins" : "full"}>
      <Stack
        grow
        shrink
        units={5}
        column={isMobileLayout}
        component="footer"
        className={classes.footer}
        vAlignContent="top"
      >
        {!isMobileLayout && (
          <Body size="small" weight="regular" className={classes.footerBody}>
            {props.children}
          </Body>
        )}
        {!isMobileLayout && <Space fluid />}
        {pipe(
          authInfo,
          option.fold(constNull, ({ flowId }) => (
            <Body size="small" weight="regular" className={classes.footerBody}>
              {unsafeLocalizedString(flowId.substring(0, 8))}
            </Body>
          ))
        )}
      </Stack>
    </ContentRow>
  );
}
