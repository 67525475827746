import { useEffect } from "react";
import { useChildSharedReducer } from "../../../BranchExperience/useSharedReducer";
import { onCloseAction, onOpenAction, reducerConfig } from "./state";

export function VirtualCardsDialogChild() {
  const [, dispatch] = useChildSharedReducer(reducerConfig);
  useEffect(() => {
    dispatch(onOpenAction);
    return () => dispatch(onCloseAction);
  }, []);
  return null;
}
