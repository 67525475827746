import { Loader } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { ExistingClientAuthenticationMethod } from "../globalDomain";
import * as remoteData from "../RemoteData";
import { useQuery } from "../useAPI";
import * as loanApi from "../StandardLoan/api";
import { StandardLoanFlowType } from "../StandardLoan/domain";
import { SignatureType } from "../StandardLoan/api";

type Props = {
  renderChild: (signatureType: Option<SignatureType>) => JSX.Element;
  defaultAuthenticationMethod: Option<ExistingClientAuthenticationMethod>;
  flowType: StandardLoanFlowType;
};

export const AuthMethodCalculateBySignatureType = (props: Props) => {
  const [getSignatureType] = useQuery(loanApi.getSignatureType);

  return pipe(
    getSignatureType,
    remoteData.fold(
      () => <Loader />,
      () => props.renderChild(option.none),
      signature =>
        pipe(
          signature.signatureType,
          option.fold(
            () => props.renderChild(option.none),
            signatureType => props.renderChild(option.some(signatureType))
          )
        )
    )
  );
};
