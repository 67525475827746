import {
  Children,
  CommonProps,
  LocalizedString,
} from "design-system/lib//util";
import {
  Box,
  LayoutContext,
  MacroStepper,
  Sticky,
  useIsMobileLayout,
  useIsTouchScreen,
} from "design-system";
import { Option } from "fp-ts/Option";
import { Stepper } from "./Stepper";
import * as classes from "./MainLayout.treat";
import { HeaderCZ } from "./HeaderCZ";
import { constNull, pipe } from "fp-ts/function";
import { boolean, option } from "fp-ts";
import { Footer } from "./Footer";
import cx from "classnames";
import { ContainerRowC } from "./grid/ContainerRowC";
import { ContainerRowB } from "./grid/ContainerRowB";
import { Container } from "./grid/Container";

type HeaderProps = React.ComponentProps<typeof HeaderCZ>;

type Props = CommonProps & {
  steps: Option<React.ComponentProps<typeof Stepper>["steps"]>;
  children: Children;
  title: HeaderProps["title"];
  subtitle?: HeaderProps["subtitle"];
  backgroundImageURL: Option<string>;
  onLogoClick: HeaderProps["onLogoClick"];
  onStepClick: (index: number) => unknown;
  iconAction: HeaderProps["iconAction"];
  dropdownHeading?: LocalizedString;
  contentBackground?: string;
  footer: Option<LocalizedString>;
  hideHeaderOnMobile?: boolean;
  isStepClickable?: (index: number) => boolean;
};

export function MainLayoutCZ(props: Props) {
  const isMobileLayout = useIsMobileLayout();
  const isTouchScreen = useIsTouchScreen();

  const header = (
    <HeaderCZ
      dropdownHeading={pipe(props.dropdownHeading, option.fromNullable)}
      title={props.title}
      subtitle={props.subtitle}
      onLogoClick={props.onLogoClick}
      iconAction={props.iconAction}
    />
  );

  const footer = pipe(
    props.footer,
    option.fold(constNull, footer => <Footer>{footer}</Footer>)
  );

  const desktopLayout = (
    <Container
      stepper={pipe(
        props.steps,
        option.map(steps => ({
          steps,
          onStepClick: props.onStepClick,
          isStepClickable: props.isStepClickable,
        }))
      )}
      contentBackground={props.contentBackground}
    >
      {header}
      {pipe(
        props.backgroundImageURL,
        option.map(url => (
          <ContainerRowB className={classes.containerRowB} imageUrl={url} />
        )),
        option.toNullable
      )}
      <ContainerRowC
        component="main"
        content={
          <Box column grow shrink>
            <Box
              column
              grow
              shrink
              className={cx(classes.desktopContent, {
                [classes.touchScreenContainer]: isTouchScreen,
              })}
            >
              {props.children}
            </Box>
            {footer}
          </Box>
        }
      />
    </Container>
  );

  const mobileLayout = (
    <Box column grow className={classes.mobileContainer}>
      {!props.hideHeaderOnMobile && header}
      {pipe(
        props.steps,
        option.fold(constNull, steps => (
          <Sticky
            className={classes.macroStepperContainer}
            top={0}
            style={{ zIndex: 2 }}
          >
            <MacroStepper steps={steps} onStepClick={props.onStepClick} />
          </Sticky>
        ))
      )}
      <Box column grow shrink className={classes.mobileContent}>
        {props.children}
      </Box>
      {footer}
    </Box>
  );

  return (
    <LayoutContext.Provider
      value={{
        hasStepper: option.isSome(props.steps),
        hasSidebar: false,
      }}
    >
      {pipe(
        isMobileLayout,
        boolean.fold(
          () => desktopLayout,
          () => mobileLayout
        )
      )}
    </LayoutContext.Provider>
  );
}
