import { DropdownOption } from "design-system";
import {
  foldTenant,
  MaritalStatus,
  MaritalStatusCZ,
  MaritalStatusSK,
} from "../../globalDomain";
import { pipe } from "fp-ts/function";
import { record, array } from "fp-ts";
import { useFormatMaritalStatus } from "../../intl";
import { Option } from "fp-ts/Option";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../selectDropdownOption";
import { useAppContext } from "../../useAppContext";
import {
  DropdownField,
  DropdownFieldProps,
} from "../DropdownField/DropdownField";

type Props = Omit<
  DropdownFieldProps<MaritalStatus>,
  "options" | "value" | "onChange" | "searchable"
> & {
  value: Option<MaritalStatus>;
  onChange: (maritalStatus: Option<MaritalStatus>) => unknown;
  isForeign?: boolean;
};

export function MaritalStatusDropdown(props: Props) {
  const formatMaritalStatus = useFormatMaritalStatus();

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const options = pipe(
    props.isForeign
      ? record.keys(MaritalStatusCZ.keys)
      : foldTenant<MaritalStatus[]>(
          tenant,
          () => record.keys(MaritalStatusSK.keys),
          () => record.keys(MaritalStatusCZ.keys)
        ),
    array.map(maritalStatus => ({
      value: maritalStatus,
      label: formatMaritalStatus(maritalStatus),
    }))
  );

  const value = selectedDropdownOption(props.value, options);

  const onChange = (value: Option<DropdownOption<MaritalStatus>>) => {
    props.onChange(dropdownOptionToValue(value));
  };

  return (
    <DropdownField
      {...props}
      options={options}
      value={value}
      onChange={onChange}
      searchable
    />
  );
}
