import * as classes from "./IFrameDialog.treat";
import cx from "classnames";
import { Dialog, useIsMobileLayout, LocalizedString } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../intl";

type Props = {
  id?: string;
  title: LocalizedString;
  onDismiss: () => unknown;
  url: string;
};

export function IFrameDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  const children = (
    <iframe
      title={props.title}
      src={props.url}
      frameBorder={0}
      className={cx({
        [classes.iframeStyle]: !isMobileLayout,
        [classes.iframeStyleMobile]: isMobileLayout,
      })}
    />
  );

  return (
    <Dialog
      id={props.id}
      variant="left"
      size="medium"
      title={props.title}
      onDismiss={option.some(props.onDismiss)}
      actions={[
        {
          action: props.onDismiss,
          label: formatMessage("Ok"),
          variant: "primary",
        },
      ]}
      children={children}
      contentClassName={isMobileLayout ? classes.contentMobile : undefined}
    />
  );
}
