import { useEffect } from "react";
import { apiCall } from "../../APICall";
import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { useCommand } from "../../useAPI";

const logAPI = apiCall({
  path: ["gateway", "noauth", "logError"],
  inputCodec: t.type({ logs: t.array(t.unknown) }),
  inputEq: eq.fromEquals(constFalse),
});

export function useLogger(enabled: boolean): void {
  const log = useCommand(logAPI, { skipTracking: true });
  useEffect(() => {
    const interval = window.setInterval(() => {
      try {
        const _logs = (window as any).eShopLogs as Array<unknown> | undefined;
        if (typeof _logs === "undefined" || _logs.length === 0) return;
        const logs = _logs.splice(0, _logs.length);
        if (!enabled) return;
        log({ logs })();
      } catch (e) {
        console.error(e);
      }
    }, 5000);
    return () => window.clearInterval(interval);
  }, [log]);
}

export function useMessageEventLogs(enabled: boolean): void {
  if (enabled) {
    window.addEventListener("message", message => {
      console.log(message.timeStamp, message.data);
    });
  }
}
