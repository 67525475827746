type Props = {
  height: number;
};

const ratio = 184 / 24;

export function Logo(props: Props) {
  return (
    <svg
      width={props.height * ratio}
      height={props.height}
      viewBox="0 0 184 24"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-591.000000, -20.000000)">
          <g transform="translate(0.000000, 20.000000)">
            <g>
              <g>
                <g>
                  <g transform="translate(591.000000, 0.000000)">
                    <g>
                      <g>
                        <path
                          d="M12.2594595,6.35675676 C12.2594595,6.35675676 12.2594595,6.29189189 12.4540541,6.03243243 C12.6486486,5.77297297 12.5837838,5.57837838 12.4540541,5.44864865 C12.3891892,5.38378378 10.5081081,4.28108108 10.5081081,4.28108108 C10.3783784,4.21621622 10.3135135,4.02162162 10.3135135,3.89189189 C10.3135135,3.63243243 10.5081081,3.43783784 10.8324324,3.30810811 C12,2.98378378 16.2162162,2.78918919 17.6432432,2.78918919 L19.5891892,2.78918919 L19.6540541,2.78918919 C17.5783784,1.03783784 14.9189189,0 12,0 C5.44864865,0 0.12972973,5.31891892 0.12972973,11.8702703 C0.12972973,14.7243243 1.16756757,17.3837838 2.85405405,19.4594595 C4.34594595,17.2540541 8.04324324,12.0648649 8.82162162,10.9621622 C9.79459459,9.53513514 12.2594595,6.35675676 12.2594595,6.35675676 Z"
                          fill="#E00A1E"
                          fillRule="nonzero"
                        />
                        <path
                          d="M22.8972973,7.13513514 L22.8972973,7.13513514 C23.4810811,6.55135135 23.8702703,6.03243243 23.8054054,5.57837838 C23.7405405,4.34594595 22.2486486,3.37297297 22.2486486,3.37297297 C22.2486486,3.37297297 22.2486486,3.37297297 22.1837838,3.30810811 C22.2486486,3.37297297 22.3135135,3.43783784 22.3783784,3.56756757 C22.7675676,4.47567568 22.1189189,5.05945946 21.7297297,5.44864865 C21.5351351,5.64324324 18.4864865,8.4972973 14.9189189,11.6108108 C11.8054054,14.3351351 8.3027027,17.1243243 6.22702703,18.6810811 C3.37297297,20.8216216 2.78918919,21.1459459 2.78918919,21.1459459 C2.72432432,21.2108108 2.59459459,21.2108108 2.46486486,21.2108108 C2.27027027,21.2108108 2.14054054,21.0810811 2.01081081,20.9513514 C2.01081081,21.0162162 2.01081081,21.2108108 2.27027027,21.5351351 L2.4,21.6648649 C2.59459459,21.8594595 2.85405405,22.1837838 3.24324324,22.4432432 C3.82702703,22.9621622 4.08648649,22.7027027 5.31891892,21.7945946 L5.31891892,21.7945946 C7.2,23.027027 9.47027027,23.8054054 11.9351351,23.8054054 C18.4864865,23.8054054 23.8054054,18.4864865 23.8054054,11.9351351 C23.9351351,10.1837838 23.5459459,8.56216216 22.8972973,7.13513514 Z"
                          fill="#E00A1E"
                          fillRule="nonzero"
                        />
                        <path
                          d="M12.4540541,6.03243243 C12.6486486,5.77297297 12.5837838,5.57837838 12.4540541,5.44864865 C12.3891892,5.38378378 10.5081081,4.28108108 10.5081081,4.28108108 C10.3783784,4.21621622 10.3135135,4.02162162 10.3135135,3.89189189 C10.3135135,3.63243243 10.5081081,3.43783784 10.8324324,3.30810811 C12,2.98378378 16.2162162,2.78918919 17.6432432,2.78918919 C18.2918919,2.78918919 19.6540541,2.78918919 20.4972973,2.85405405 C21.4702703,2.91891892 22.2486486,3.11351351 22.3783784,3.56756757 C22.7675676,4.47567568 22.1189189,5.05945946 21.7297297,5.44864865 C21.5351351,5.64324324 18.4864865,8.4972973 14.9189189,11.6108108 C11.8054054,14.3351351 8.3027027,17.1243243 6.22702703,18.6810811 C3.37297297,20.8216216 2.78918919,21.1459459 2.78918919,21.1459459 C2.72432432,21.2108108 2.59459459,21.2108108 2.46486486,21.2108108 C2.14054054,21.2108108 1.94594595,21.0162162 1.94594595,20.6918919 C1.94594595,20.5621622 2.01081081,20.4972973 2.01081081,20.3675676 C2.01081081,20.3675676 7.65405405,12.2594595 8.62702703,10.8972973 C9.66486486,9.53513514 12.1297297,6.35675676 12.1297297,6.35675676 C12.1297297,6.35675676 12.2594595,6.29189189 12.4540541,6.03243243 Z"
                          fill="#FFFFFF"
                        />
                        <path
                          d="M41.3837838,2.14054054 L41.3837838,16.9945946 C41.3837838,20.2378378 38.5297297,22.7675676 34.7027027,22.7675676 C30.9405405,22.7675676 28.0216216,20.3027027 28.0216216,16.9945946 L28.0216216,5.64324324 C28.0216216,3.6972973 29.5783784,2.14054054 31.4594595,2.14054054 L31.7189189,2.14054054 L31.7189189,16.9945946 C31.7189189,18.7459459 33.2756757,19.8486486 34.8324324,19.8486486 C36.3243243,19.8486486 37.8810811,18.8756757 37.8810811,16.9945946 L37.8810811,5.44864865 C37.8810811,3.63243243 39.372973,2.14054054 41.1891892,2.14054054 L41.3837838,2.14054054 L41.3837838,2.14054054 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M48.7135135,7.39459459 C53.772973,7.39459459 54.5513514,10.2486486 54.5513514,11.9351351 L54.5513514,22.5081081 L54.4216216,22.5081081 C52.5405405,22.5081081 50.9837838,20.9513514 50.9837838,19.0702703 L50.9837838,11.9351351 C50.9837838,10.9621622 50.5945946,9.72972973 48.5837838,9.72972973 C47.6756757,9.72972973 47.0918919,9.85945946 46.5081081,10.0540541 L46.5081081,22.4432432 L42.8756757,22.4432432 L42.8756757,8.43243243 C44.4324324,7.78378378 46.9621622,7.39459459 48.7135135,7.39459459 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M57.9891892,2.14054054 C59.0918919,2.14054054 59.9351351,2.98378378 59.9351351,3.95675676 C59.9351351,4.92972973 59.0918919,5.70810811 57.9891892,5.70810811 C56.8864865,5.70810811 56.0432432,4.92972973 56.0432432,3.95675676 C55.9783784,2.91891892 56.8864865,2.14054054 57.9891892,2.14054054 Z M59.7405405,7.39459459 L59.7405405,19.1351351 C59.7405405,21.0162162 58.2486486,22.572973 56.3027027,22.572973 L56.172973,22.572973 L56.172973,7.39459459 L59.7405405,7.39459459 L59.7405405,7.39459459 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M70.0540541,1.81621622 C71.7405405,1.81621622 73.3621622,2.20540541 74.6594595,2.98378378 L74.6594595,6.55135135 C73.8162162,5.51351351 72.7135135,4.73513514 70.8324324,4.73513514 C67.1351351,4.73513514 64.8648649,8.36756757 64.8648649,12.1297297 C64.8648649,15.6972973 67.2648649,19.5243243 70.8324324,19.5243243 C72.3891892,19.5243243 73.8162162,19.0702703 74.6594595,17.9027027 L74.6594595,21.4054054 C73.6216216,22.1837838 71.9351351,22.6378378 69.9891892,22.6378378 C64.1513514,22.6378378 61.0378378,17.2540541 61.0378378,12.1297297 C61.1027027,7.13513514 64.2162162,1.81621622 70.0540541,1.81621622 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M81.0810811,7.39459459 C81.6648649,7.39459459 82.4432432,7.45945946 83.0918919,7.52432432 L83.0918919,10.4432432 C81.8594595,9.53513514 80.172973,9.85945946 79.4594595,10.2486486 L79.4594595,19.1351351 C79.4594595,21.0162162 77.9027027,22.572973 76.0216216,22.572973 L75.8918919,22.572973 L75.8918919,8.56216216 C77.3189189,7.84864865 78.7459459,7.39459459 81.0810811,7.39459459 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M95.6756757,15.1135135 L86.6594595,16.3459459 C86.9837838,18.8108108 88.2162162,20.1081081 90.4864865,20.1081081 C93.0162162,20.1081081 94.2486486,19.2648649 95.2216216,18.6162162 L95.2216216,21.6 C94.3135135,22.1189189 92.627027,22.7027027 90.1621622,22.7027027 C85.7513514,22.7027027 83.1567568,19.7189189 83.1567568,14.9837838 C83.1567568,10.3783784 85.6216216,7.32972973 89.5783784,7.32972973 C93.5351351,7.32972973 95.7405405,9.92432432 95.7405405,14.5297297 L95.7405405,15.1135135 L95.6756757,15.1135135 Z M92.5621622,13.0378378 C92.4324324,10.0540541 90.6162162,9.72972973 89.5783784,9.72972973 L89.4486486,9.72972973 C87.4378378,9.79459459 86.4648649,11.2216216 86.4648649,13.8810811 L92.5621622,13.0378378 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M108.648649,2.14054054 L108.648649,22.3783784 C107.805405,22.572973 104.951351,22.7675676 103.783784,22.7675676 L103.524324,22.7675676 C99.1135135,22.7027027 96.7135135,20.172973 96.7135135,15.6324324 C96.7135135,11.027027 99.2432432,8.04324324 103.135135,8.04324324 C103.913514,8.04324324 104.562162,8.10810811 105.081081,8.23783784 L105.081081,5.64324324 C105.081081,3.76216216 106.572973,2.20540541 108.518919,2.20540541 L108.648649,2.20540541 L108.648649,2.14054054 Z M105.145946,10.3135135 C104.756757,10.1837838 104.367568,10.1837838 103.913514,10.1837838 C100.92973,10.1837838 100.345946,13.0378378 100.345946,15.4378378 C100.345946,18.7459459 101.448649,20.4324324 103.718919,20.4324324 C104.108108,20.4324324 104.951351,20.3675676 105.210811,20.3027027 L105.210811,10.3135135 L105.145946,10.3135135 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M112.151351,2.14054054 C113.254054,2.14054054 114.097297,2.98378378 114.097297,3.95675676 C114.097297,4.92972973 113.254054,5.70810811 112.151351,5.70810811 C111.048649,5.70810811 110.205405,4.92972973 110.205405,3.95675676 C110.140541,2.91891892 111.048649,2.14054054 112.151351,2.14054054 Z M113.902703,7.39459459 L113.902703,19.1351351 C113.902703,21.0162162 112.410811,22.572973 110.464865,22.572973 L110.335135,22.572973 L110.335135,7.39459459 L113.902703,7.39459459 L113.902703,7.39459459 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M120.259459,2.14054054 L120.259459,7.39459459 L122.594595,7.39459459 L122.594595,10.3135135 L120.259459,10.3135135 L120.259459,18.3567568 C120.259459,19.3297297 120.583784,19.6540541 121.621622,19.6540541 C121.881081,19.6540541 122.27027,19.5891892 122.594595,19.4594595 L122.594595,22.4432432 C122.464865,22.5081081 121.751351,22.7675676 120.778378,22.7675676 C118.118919,22.7675676 116.691892,21.4702703 116.691892,19.1351351 L116.691892,10.3135135 L115.135135,10.3135135 L115.135135,7.39459459 L116.691892,7.39459459 L116.691892,5.57837838 C116.691892,3.6972973 118.183784,2.14054054 120.064865,2.14054054 L120.259459,2.14054054 L120.259459,2.14054054 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M139.2,11.4810811 L139.2,11.5459459 C141.145946,11.9351351 143.805405,13.6216216 143.805405,16.6054054 C143.805405,20.6918919 140.562162,22.4432432 135.762162,22.4432432 C131.221622,22.4432432 129.535135,22.3783784 129.535135,22.3783784 L129.535135,2.85405405 C130.832432,2.20540541 132.972973,1.68648649 135.502703,1.68648649 C140.562162,1.68648649 143.091892,3.89189189 143.091892,6.87567568 C143.156757,9.79459459 141.016216,11.0918919 139.2,11.4810811 Z M139.394595,7.2 C139.394595,4.92972973 138.097297,3.89189189 135.567568,3.89189189 C134.659459,3.89189189 133.945946,4.02162162 133.232432,4.21621622 L133.232432,10.6378378 L134.4,10.6378378 C138.162162,10.8324324 139.394595,9.66486486 139.394595,7.2 Z M134.4,12.5837838 L133.232432,12.5837838 L133.232432,20.172973 L135.697297,20.172973 C138.162162,20.172973 139.978378,19.2 139.978378,16.6054054 C139.978378,13.7513514 138.356757,12.4540541 134.4,12.5837838 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M152.756757,12.972973 L152.756757,12 C152.756757,10.0540541 151.589189,9.53513514 149.837838,9.53513514 C148.410811,9.53513514 146.983784,10.0540541 145.362162,10.8972973 L145.362162,8.4972973 C146.335135,8.04324324 148.086486,7.32972973 150.421622,7.32972973 C153.47027,7.32972973 156.064865,8.88648649 156.064865,12.6486486 L156.064865,22.3135135 C155.091892,22.5081081 152.756757,22.7027027 151.32973,22.7027027 L150.551351,22.7027027 C146.52973,22.7027027 144.454054,21.0162162 144.454054,18.1621622 C144.454054,14.2702703 148.216216,13.3621622 152.756757,12.972973 Z M152.756757,14.5945946 C149.578378,14.9189189 147.632432,15.5675676 147.632432,18.0972973 C147.632432,19.9783784 148.8,20.8216216 150.745946,20.8216216 C151.524324,20.8216216 152.432432,20.7567568 152.756757,20.6918919 L152.756757,14.5945946 L152.756757,14.5945946 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M163.394595,7.32972973 C168.454054,7.32972973 169.297297,10.1837838 169.297297,11.8702703 L169.297297,22.5081081 L169.167568,22.5081081 C167.286486,22.5081081 165.664865,20.9513514 165.664865,19.0702703 L165.664865,11.9351351 C165.664865,10.9621622 165.275676,9.72972973 163.264865,9.72972973 C162.356757,9.72972973 161.772973,9.85945946 161.189189,10.0540541 L161.189189,22.5081081 L157.556757,22.5081081 L157.556757,8.43243243 C159.113514,7.78378378 161.643243,7.32972973 163.394595,7.32972973 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                        <path
                          d="M174.486486,19.0054054 C174.486486,20.9513514 172.994595,22.5081081 170.918919,22.5081081 L170.918919,22.5081081 L170.918919,5.57837838 C170.918919,3.63243243 172.410811,2.07567568 174.486486,2.07567568 L174.486486,2.07567568 L174.486486,14.4 L178.962162,7.71891892 L182.335135,7.71891892 L177.72973,14.4648649 L183.178378,22.5081081 L179.351351,22.5081081 L174.486486,14.9837838 L174.486486,19.0054054 L174.486486,19.0054054 Z"
                          fill="#212121"
                          fillRule="nonzero"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
