import { boolean, option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import * as classes from "./Container.treat";
import { useEffect, useRef } from "react";
import { Box, Children } from "design-system";
import { Stepper } from "../Stepper";
import { stepSize } from "../stepSize";

type Props = {
  children: Children;
  stepper: Option<React.ComponentProps<typeof Stepper>>;
  contentBackground?: string;
};

export function Container(props: Props) {
  const hasStepper = pipe(props.stepper, option.isSome);

  const stepperWrapperRef = useRef<HTMLElement>(null);
  const stepperContainerRef = useRef<HTMLElement>(null);

  const stepper = pipe(
    props.stepper,
    option.fold(
      () => null,
      stepperProps => (
        <Box className={classes.stepper} ref={stepperWrapperRef}>
          <Stepper {...stepperProps} ref={stepperContainerRef} />
        </Box>
      )
    )
  );

  const leftPlaceholderWidth = pipe(
    hasStepper,
    boolean.fold(
      () => 0,
      () => stepSize
    )
  );

  useEffect(() => {
    if (stepperContainerRef.current && stepperWrapperRef.current) {
      const stepsNo = option.isSome(props.stepper)
        ? props.stepper.value.steps.lefts.length +
          props.stepper.value.steps.rights.length +
          1
        : 0; // 1 is the focus length

      const activeStepElement = stepperContainerRef.current.querySelector(
        "[data-step-state='active']"
      );

      let buttonHeight = 0,
        buttonMargins = 0,
        buttonOffset = 0;

      if (activeStepElement) {
        buttonHeight = activeStepElement.getBoundingClientRect().height;
        buttonMargins = 8;
        buttonOffset = buttonHeight + buttonMargins;
        const offset = buttonOffset / 2;
        stepperContainerRef.current.style.height = `${
          buttonOffset * stepsNo + offset
        }px`;
      }

      const stepperWrapperHeight = stepperWrapperRef.current.getBoundingClientRect()
        .height;
      const stepperContainerHeight = stepperContainerRef.current.getBoundingClientRect()
        .height;

      if (stepperContainerHeight > stepperWrapperHeight) {
        if (activeStepElement) {
          const activeElementTop = activeStepElement.getBoundingClientRect().y;
          if (activeElementTop > stepperWrapperHeight / 2) {
            const activeStepMiddleDelta = Math.ceil(
              activeElementTop - stepperWrapperHeight / 2
            );

            stepperWrapperRef.current.scrollTop +=
              activeStepMiddleDelta + buttonOffset;
          }
        }
      } else {
        const newTop =
          stepperWrapperRef.current.getBoundingClientRect().height / 2 -
          stepperContainerRef.current.getBoundingClientRect().height / 2;
        stepperContainerRef.current.style.top = `${newTop}px`;
        stepperContainerRef.current.style.position = `relative`;
      }
    }
  }, [props.stepper]);

  return (
    <Box grow className={classes.container}>
      <Box width={leftPlaceholderWidth} />
      {stepper}
      <Box
        column
        grow
        shrink
        style={{
          background: props.contentBackground,
        }}
        className={
          classes.content[hasStepper ? "withStepper" : "withoutStepper"]
        }
      >
        {props.children}
      </Box>
    </Box>
  );
}
