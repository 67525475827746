import { useRef, useState } from "react";
import { option, array } from "fp-ts";
import { identity, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { sequenceS } from "fp-ts/Apply";
import {
  Dropdown,
  DropdownOption,
  fieldIssues,
  Stack,
  unsafePositiveInteger,
  LocalizedString,
  unsafeLocalizedString,
  TextChildren,
  FieldProps,
  Field,
} from "design-system";
import { MonthYear } from "../../globalDomain";
import { months, useFormatMessage, useFormatMonth } from "../../intl";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../selectDropdownOption";

type Props = FieldProps<Option<MonthYear>> & {
  id?: string;
  clearable: boolean;
  /**
   * Optional, longer description for the input field
   */
  description?: LocalizedString;
  /**
   * Whether to automatically focus the input upon first render.
   * Only a single input element in a given view should have this prop set to true,
   * otherwise the last rendered one obtains the focus.
   */
  autoFocus?: boolean;
  assistiveText?: TextChildren;
  minYear: number;
  maxYear: number;
  ascending?: boolean;
  searchable: boolean;
};

type MonthDropdownOption = DropdownOption<MonthYear["month"]>;
type YearDropdownOption = DropdownOption<MonthYear["year"]>;

type MonthYearDropdownState = {
  month: Option<MonthDropdownOption>;
  year: Option<YearDropdownOption>;
};

const getMonthYearOption = ({ month, year }: MonthYearDropdownState) =>
  pipe(
    { month: dropdownOptionToValue(month), year: dropdownOptionToValue(year) },
    sequenceS(option.option)
  );

/**
 * Helpers
 */
export function getMonthYearString(data: { month: number; year: number }) {
  return `${data.year}${String(data.month).padStart(2, "0")}`;
}

export function MonthYearField(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMonth = useFormatMonth();

  const monthOptions = months.map(month => ({
    value: month,
    label: formatMonth(month),
  }));

  const yearOptions: YearDropdownOption[] = pipe(
    array.range(props.minYear, props.maxYear),
    props.ascending ? identity : array.reverse
  ).map(year => ({
    value: unsafePositiveInteger(year),
    label: unsafeLocalizedString(year),
  }));

  const [month, setMonth] = useState<Option<MonthDropdownOption>>(
    selectedDropdownOption(
      pipe(
        props.value,
        option.map(value => value.month)
      ),
      monthOptions
    )
  );
  const [year, setYear] = useState<Option<YearDropdownOption>>(
    selectedDropdownOption(
      pipe(
        props.value,
        option.map(value => value.year)
      ),
      yearOptions
    )
  );

  const monthDropdownRef = useRef(null);
  const yearDropdownRef = useRef(null);

  const onBlurDropdown = () => {
    setTimeout(() => {
      if (
        document.activeElement !==
          (monthDropdownRef.current &&
            (monthDropdownRef.current as any).select &&
            (monthDropdownRef.current as any).select.inputRef) &&
        document.activeElement !==
          (yearDropdownRef.current &&
            (yearDropdownRef.current as any).select &&
            (yearDropdownRef.current as any).select.inputRef)
      ) {
        props.onBlur();
      }
    }, 0);
  };

  const onChangeDropdown = (monthYear: MonthYearDropdownState) =>
    pipe(monthYear, getMonthYearOption, props.onChange);

  return (
    <Field
      label={props.label}
      description={props.description}
      issues={props.issues}
      assistiveText={props.assistiveText}
    >
      {labelId => (
        <Stack id={props.id} units={4}>
          <Dropdown
            {...props}
            ref={monthDropdownRef}
            width="50%"
            name={`${props.name}_month`}
            options={monthOptions}
            id={props.id && `${props.id}_month`}
            value={month}
            onChange={value => {
              setMonth(value);
              onChangeDropdown({ year, month: value });
            }}
            onBlur={onBlurDropdown}
            isDisabled={props.disabled}
            aria-labelledby={labelId}
            placeholder={formatMessage("Month")}
            issueType={pipe(props.issues, option.map(fieldIssues.issuesType))}
            fieldLabel={props.label}
            cancelLabel={formatMessage("Cancel")}
            clearSelectionLabel={formatMessage("Dropdown.clearSelection")}
            filterInputPlaceholder={formatMessage(
              "Dropdown.filterInputPlaceholder"
            )}
            noResultsLabel={formatMessage("NoResults")}
          />
          <Dropdown
            {...props}
            ref={yearDropdownRef}
            width="25%"
            name={`${props.name}_year`}
            options={yearOptions}
            id={props.id && `${props.id}_year`}
            value={year}
            onChange={value => {
              setYear(value);
              onChangeDropdown({ year: value, month });
            }}
            onBlur={onBlurDropdown}
            isDisabled={props.disabled}
            autoFocus={false}
            aria-labelledby={labelId}
            placeholder={formatMessage("Year")}
            issueType={pipe(props.issues, option.map(fieldIssues.issuesType))}
            fieldLabel={props.label}
            cancelLabel={formatMessage("Cancel")}
            clearSelectionLabel={formatMessage("Dropdown.clearSelection")}
            filterInputPlaceholder={formatMessage(
              "Dropdown.filterInputPlaceholder"
            )}
            noResultsLabel={formatMessage("NoResults")}
          />
        </Stack>
      )}
    </Field>
  );
}
