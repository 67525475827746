import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option, either, taskEither, eq, boolean } from "fp-ts";
import {
  Children,
  useLoadingStatusWrite,
  AuthenticationContext,
  TokenInfo,
  AuthenticationInfo,
  useAuthenticationContext,
  useStableEquality,
  useIsTouchScreen,
} from "design-system";
import { UUID } from "io-ts-types/lib/UUID";
import * as t from "io-ts";
import { fetchAPI, resetRefreshStatus, setLatestTokenInfo } from "./APICall";
import { useAPIEndpoint } from "./useAPIEndpoint";
import { useAppContext } from "./useAppContext";
import { TaskEither } from "fp-ts/TaskEither";
import { PortalBlocked, UnauthorizedError } from "./globalDomain";
import { IO } from "fp-ts/IO";
import { usePortalStatusContext } from "./PortalStatusContext";
import { TabLock } from "./Common/TabLock/TabLock";
import { useIsSBChannel } from "./useChannel";

const { v4: uuid } = require("uuid");
const newTabUuid = uuid();

type Props = {
  children: Children;
  authInfo: Option<AuthenticationInfo>;
  is3P?: boolean;
  isChangingPassword?: boolean;
  potentialClientToken: TaskEither<unknown, Option<TokenInfo>>;
  onLogout?: IO<void>;
};

function getPersistedAuthInfo(
  sessionStorageKey: string
): Option<AuthenticationInfo> {
  return pipe(
    either.tryCatch(
      () => sessionStorage.getItem(sessionStorageKey),
      either.toError
    ),
    either.chainW(t.string.decode),
    either.chainW(s => either.parseJSON(s, either.toError)),
    either.chainW(AuthenticationInfo.decode),
    option.fromEither
  );
}

function setPersistedAuthInfo(
  sessionStorageKey: string,
  value: Option<AuthenticationInfo>
) {
  pipe(
    value,
    option.fold(
      () => option.tryCatch(() => sessionStorage.removeItem(sessionStorageKey)),
      value =>
        option.tryCatch(() =>
          sessionStorage.setItem(
            sessionStorageKey,
            JSON.stringify(AuthenticationInfo.encode(value))
          )
        )
    )
  );
}

function setTabLock(uuid: String) {
  const lock = { value: uuid, lastTimestamp: new Date().getTime() };
  try {
    localStorage.setItem("tabLock", JSON.stringify(lock));
  } catch (e) {
    console.error("LocalStorage Error", e);
  }
}

function getPersistedTabLock() {
  try {
    let lock = localStorage.getItem("tabLock");
    return lock != null ? JSON.parse(lock) : 0;
  } catch (e) {
    console.error("LocalStorage Error", e);
  }
}

function removeTabLock() {
  try {
    localStorage.removeItem("tabLock");
  } catch (e) {
    console.error("LocalStorage Error", e);
  }
}

function canOpenAnotherTab(uuid: String): boolean {
  const tabLock = getPersistedTabLock();
  const lastTimestamp = tabLock["lastTimestamp"];
  if (tabLock["value"] === undefined || tabLock["value"] === uuid) {
    //same tab
    return true;
  }
  return !isNaN(lastTimestamp)
    ? new Date().getTime() - lastTimestamp > 10000
    : true;
}

function isEmailVerificationLink() {
  return window.location.toString().includes("emailverification");
}

function isBranchExperienceDualSession() {
  return window.location.toString().includes("screen=external");
}

function isDocumentsUploadLink() {
  return window.location.toString().includes("uploaddocumentsclient");
}

function isMobileUploadLink() {
  return window.location.toString().includes("mobileUpload");
}

function skipTabLock() {
  return (
    isBranchExperienceDualSession() ||
    isEmailVerificationLink() ||
    isDocumentsUploadLink() ||
    isMobileUploadLink()
  );
}

const ReassignTokenResponse = t.type({ flowId: UUID }, "ReassignTokenResponse");

export const AuthenticationContextProvider = (props: Props) => {
  const isTouchscreen = useIsTouchScreen();
  const isSBChannel = useIsSBChannel();

  const LOCAL_STORAGE_KEY = isTouchscreen ? "auth-touch" : "auth";

  const { trackSubmission, resetSubmissionCount } = useLoadingStatusWrite();
  const parentAuthContext = useAuthenticationContext();

  const authInfo = useRef<Option<AuthenticationInfo>>(
    pipe(
      props.authInfo,
      option.alt(() => getPersistedAuthInfo(LOCAL_STORAGE_KEY))
    )
  );

  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);

  function login(newAuthInfo: AuthenticationInfo) {
    authInfo.current = option.some(newAuthInfo);
    setPersistedAuthInfo(LOCAL_STORAGE_KEY, option.some(newAuthInfo));
    parentAuthContext.login(newAuthInfo);
    forceUpdate();
  }

  function logout() {
    authInfo.current = option.none;
    setPersistedAuthInfo(LOCAL_STORAGE_KEY, option.none);
    parentAuthContext.logout();
    resetRefreshStatus();
    forceUpdate();
    resetSubmissionCount();
    if (props.onLogout) props.onLogout();
  }

  const [propsAuthInfoStableEqWithCurrent] = useStableEquality(
    option.getEq(
      eq.getStructEq({
        token: eq.eqStrict,
        refreshToken: eq.eqStrict,
        flowId: eq.eqStrict,
      })
    ),
    authInfo.current
  );

  const [propsAuthInfoStableEq] = useStableEquality(
    option.getEq(
      eq.getStructEq({
        token: eq.eqStrict,
        refreshToken: eq.eqStrict,
        flowId: eq.eqStrict,
      })
    ),
    props.authInfo
  );
  useEffect(() => {
    if (option.isSome(props.authInfo) && !props.isChangingPassword) {
      login(props.authInfo.value);
    }
  }, [propsAuthInfoStableEq]);

  useEffect(() => {
    if (!(skipTabLock() || isSBChannel)) {
      if (!canOpenAnotherTab(newTabUuid)) {
        setShowTabLock(true);
      } else {
        setShowTabLock(false);
      }
    }
  }, [canOpenAnotherTab(newTabUuid)]);

  function refreshToken(tokenInfo: TokenInfo) {
    if (option.isSome(authInfo.current)) {
      const newAuthInfo = option.some({
        ...tokenInfo,
        flowId: authInfo.current.value.flowId,
      });
      authInfo.current = newAuthInfo;
      setPersistedAuthInfo(LOCAL_STORAGE_KEY, newAuthInfo);
      parentAuthContext.refreshToken(tokenInfo);
      forceUpdate();
    }
  }

  const apiEndpoint = useAPIEndpoint();
  const { apiParameters } = useAppContext();
  const [showTabLock, setShowTabLock] = useState(false);
  const {
    retrieveCurrentPortalStatus: checkPortalBlocked,
    setAlertModalVisible,
  } = usePortalStatusContext();

  const onPortalBlocked = () => {
    checkPortalBlocked();
    setAlertModalVisible(true);
  };

  const startNewFlow = (keepClient: boolean) =>
    pipe(
      authInfo,
      authInfo => authInfo.current,
      option.fold(
        () => taskEither.left("Not authenticated yet"),
        ({ token, refreshToken, flowId }) =>
          pipe(
            !(skipTabLock() || isSBChannel)
              ? canOpenAnotherTab(newTabUuid)
              : true,
            boolean.fold(
              () =>
                taskEither.fromIO(() => {
                  setShowTabLock(true);
                }),
              () => {
                setShowTabLock(false);
                return pipe(
                  fetchAPI({
                    apiParameters,
                    tokenInfo: option.some({ token, refreshToken }),
                    apiEndpoint,
                    url: props.is3P
                      ? [
                          "authorization",
                          "3P",
                          keepClient ? "reassignKeepClient" : "reassign",
                        ]
                      : [
                          "authorization",
                          "token",
                          keepClient ? "reassignKeepClient" : "reassign",
                        ],
                    bodyType: "JSON",
                    body: {
                      flowId,
                    },
                    flowId: option.fromNullable(flowId),
                  }),
                  taskEither.orElse(err =>
                    pipe(
                      err,
                      UnauthorizedError.decode,
                      either.fold(
                        () =>
                          pipe(
                            err,
                            PortalBlocked.decode,
                            either.fold(
                              () => taskEither.left(err),
                              () => {
                                return pipe(
                                  taskEither.fromIO(() => onPortalBlocked()),
                                  taskEither.chain(() => taskEither.left(err))
                                );
                              }
                            )
                          ),
                        () => taskEither.leftIO(logout)
                      )
                    )
                  ),
                  taskEither.chain(r => {
                    if (
                      r.response.status === 401 ||
                      r.response.status === 403 ||
                      r.response.status === 500
                    ) {
                      return taskEither.leftIO(logout);
                    }
                    return taskEither.right(r.response.body);
                  }),
                  taskEither.chainEitherKW(ReassignTokenResponse.decode),
                  taskEither.chainW(({ flowId }) =>
                    taskEither.fromIO(() => {
                      login({ flowId, token, refreshToken });
                    })
                  )
                );
              }
            )
          )
      ),
      trackSubmission
    );

  const transitionToPotentialClient = pipe(
    props.potentialClientToken,
    taskEither.chain(
      option.fold(
        () => taskEither.of<unknown, Option<TokenInfo>>(option.none),
        tokenInfo =>
          taskEither.fromIO(() => {
            refreshToken(tokenInfo);
            setLatestTokenInfo(option.some(tokenInfo));
            return option.some(tokenInfo);
          })
      )
    )
  );

  const authenticated = option.isSome(authInfo.current);
  const lastFlowId = pipe(
    authInfo.current,
    option.fold(
      () => "",
      a => a.flowId
    )
  );

  // Only update the consumers if the state changes from logged in to logged out, or viceversa.
  // Every other update (token changed, refreshToken changed) would cause too many re-renders for no reason,
  // since the latest token/refreshToken are also tracked by APICall itself
  const value = useMemo(() => {
    return {
      authInfo: authInfo.current,
      login,
      logout,
      startNewFlow: startNewFlow(false),
      startNewFlowKeepClient: startNewFlow(true),
      refreshToken,
      transitionToPotentialClient,
    };
    // eslint-disable-next-line
  }, [authenticated, lastFlowId, propsAuthInfoStableEqWithCurrent]);

  useEffect(() => {
    if (!(skipTabLock() || isSBChannel)) {
      const tabLock = getPersistedTabLock();
      const uuidFromStorage = tabLock["value"];
      if (
        authenticated &&
        (uuidFromStorage === undefined ||
          uuidFromStorage === newTabUuid ||
          new Date().getTime() - tabLock["lastTimestamp"] > 10000)
      ) {
        const interval = window.setInterval(() => {
          setTabLock(newTabUuid);
        }, 5000);
        return () => window.clearInterval(interval);
      }
    }
    return;
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", removeTabLock);
    window.addEventListener("unload", removeTabLock);
  }, []);

  return (
    <AuthenticationContext.Provider value={value}>
      {props.children}
      {showTabLock && <TabLock />}
    </AuthenticationContext.Provider>
  );
};
