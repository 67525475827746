import { ComponentProps } from "react";
import {
  Box,
  Children,
  ContentRow,
  PageHeading,
  useIsMobileLayout,
} from "design-system";
import { CommonProps } from "./CommonProps";

type Props = CommonProps & {
  pageHeading?: ComponentProps<typeof PageHeading>;
  children: Children;
};

export function MainContent({ pageHeading, children }: Props) {
  const isMobileLayout = useIsMobileLayout();
  return (
    <ContentRow type="full">
      <Box shrink grow column>
        {pageHeading && !isMobileLayout && (
          <PageHeading contentRowType="full" {...pageHeading} />
        )}
        {children}
      </Box>
    </ContentRow>
  );
}
