import { option } from "fp-ts";
import { constant, constFalse, pipe } from "fp-ts/function";
import * as t from "io-ts";
import { fromNullable } from "io-ts-types/lib/fromNullable";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { withFallback } from "io-ts-types/lib/withFallback";
import {
  DateFromSQLString,
  DocumentPurpose,
  GenericError,
  NonNegative,
  optionFromUndefined,
} from "../globalDomain";
import { CheckPrimaryIdEnums } from "../UKontoSecondPart/api";
import {
  AddressOCR,
  PersonalDataOCR,
  PersonalDocumentOCR,
} from "../IdUpload/domain";
import { IncomeSource, SourceOfIncomeId } from "../KYC/domain";
import { DocumentType } from "../UploadDocuments/domain";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";
import * as standardLoanSaleAPI from "../StandardLoan/api";
import { FormState } from "../IdUpload/ClientData/PersonalDocumentForm/PersonalDocumentForm";
import { NewMortgageCheckClientStatus } from "../MortgageDashboard/api";
import { validableDate } from "design-system";
import { Option } from "fp-ts/Option";
import { CheckPrimaryIdEnumsCF } from "../StandardLoan/api";

export const UserStatus = t.keyof({ A: true, B: true, C: true, L: true });

export type UserStatus = t.TypeOf<typeof UserStatus>;

export const HousingType = t.keyof(
  {
    Owner: true,
    Renter: true,
    Other: true,
    Parents: true,
    Cooperative: true,
  },
  "HousingType"
);
export type HousingType = t.TypeOf<typeof HousingType>;

export const CarOwnershipType = t.keyof(
  {
    DONT_OWN: true,
    OWN: true,
    OWN_WITH_LEASING_OR_LOAN: true,
    COMPANY_CAR: true,
  },
  "CarOwnership"
);
export type CarOwnershipType = t.TypeOf<typeof CarOwnershipType>;

export const SectionStatus = t.keyof(
  {
    OK: true,
    NOK: true,
  },
  "SectionStatus"
);
export type SectionStatus = t.TypeOf<typeof SectionStatus>;

export const SoniaStatus = t.type(
  {
    updated: withFallback(t.boolean, false),
    updateDate: optionFromUndefined(DateFromSQLString),
  },
  "SoniaStatus"
);
export type SoniaStatus = t.TypeOf<typeof SoniaStatus>;

const PersonalProfilePersonalDocument = t.intersection(
  [
    PersonalDocumentOCR,
    t.type({
      documentType: DocumentType,
      issuerTown: optionFromUndefined(t.string),
      issuerCountry: optionFromUndefined(t.string),
      expired: withFallback(t.boolean, false),
      stolen: withFallback(t.boolean, false),
      recalled: withFallback(t.boolean, false),
    }),
  ],
  "PersonalProfilePersonalDocument"
);

export type PersonalProfilePersonalDocument = t.TypeOf<
  typeof PersonalProfilePersonalDocument
>;

export function formStateFromPersonalProfilePersonalDocument(
  document: PersonalProfilePersonalDocument
): FormState {
  return {
    idNumber: pipe(document.idNumber, option.getOrElse(constant(""))),
    dateOfIssuing: pipe(
      document.dateOfIssuing,
      option.map(validableDate.fromDate)
    ),
    validUntil: pipe(document.validUntil, option.map(validableDate.fromDate)),
    authority: document.issuer,
    authorityMandatory: document.authorityMandatory,
    issuer: document.issuerTown,
    issuerMandatory: document.issuerMandatory,
  };
}

const ContactStatus = t.keyof({
  VERIFIED: true,
  NOTVERIFIED: true,
  CORRUPTED: true,
  EXPIRED: true,
  MISSING: true,
});
export type ContactStatus = t.TypeOf<typeof ContactStatus>;

export const ClientData = t.type(
  {
    personalData: PersonalDataOCR,
    contactData: t.type(
      {
        phoneNumber: optionFromNullable(NonEmptyString),
        phoneNumberStatus: fromNullable(ContactStatus, "NOTVERIFIED"),
        email: optionFromNullable(NonEmptyString),
        emailStatus: fromNullable(ContactStatus, "NOTVERIFIED"),
        subSectionStatus: SectionStatus,
      },
      "ContactData"
    ),
    idDocuments: t.type({
      primaryDocument: optionFromNullable(PersonalProfilePersonalDocument),
      secondaryDocument: optionFromNullable(PersonalProfilePersonalDocument),
      subSectionStatus: SectionStatus,
      soniaStatus: optionFromUndefined(SoniaStatus),
    }),
    addresses: t.type({
      permanentAddress: optionFromUndefined(AddressOCR),
      contactAddress: optionFromUndefined(AddressOCR),
      currentAddress: optionFromUndefined(AddressOCR),
      subSectionStatus: SectionStatus,
    }),
    otherDocumentUsedForAuth: t.boolean,
    sectionStatus: SectionStatus,
  },
  "ClientData"
);
export type ClientData = t.TypeOf<typeof ClientData>;
export const IncomeSourceExtended = t.union([IncomeSource, SourceOfIncomeId]);
export type IncomeSourceExtended = t.TypeOf<typeof IncomeSourceExtended>;

export const AdditionalData = t.type(
  {
    monthlyIncome: optionFromNullable(NonNegative),
    sourceOfIncome: optionFromNullable(IncomeSourceExtended),
    companyName: optionFromNullable(NonEmptyString),
    companyIco: optionFromNullable(NonEmptyString),
    housingType: optionFromNullable(HousingType),
    carOwnership: optionFromNullable(CarOwnershipType),
    subSectionStatus: SectionStatus,
  },
  "AdditionalData"
);
export type AdditionalData = t.TypeOf<typeof AdditionalData>;

export const AdditionalInformation = t.type(
  {
    additionalData: AdditionalData,
    marketingConsent: t.type({
      marketingConsentValue: fromNullable(t.boolean, false),
      subSectionStatus: SectionStatus,
    }),
    sectionStatus: SectionStatus,
  },
  "AdditionalInformation"
);
export type AdditionalInformation = t.TypeOf<typeof AdditionalInformation>;

export const BankIdStatus = t.type({
  active: t.boolean,
});
export type BankIdStatus = t.TypeOf<typeof BankIdStatus>;

export const SecurityProfile = t.type({
  credentialsAndStatus: t.type({
    userId: optionFromNullable(NonEmptyString),
    status: fromNullable(UserStatus, "B"),
    subSectionStatus: SectionStatus,
    hasPasswordForCommunication: t.boolean,
    hasSecurityPin: t.boolean,
  }),
  biometricConsent: t.type({
    biometricConsentValue: optionFromUndefined(t.boolean),
    subSectionStatus: SectionStatus,
  }),
  sectionStatus: SectionStatus,
  hasAssecoMtoken: t.boolean,
  bankIdStatus: optionFromNullable(BankIdStatus),
});
export type SecurityProfile = t.TypeOf<typeof SecurityProfile>;

export const PersonalProfile = t.type({
  clientData: ClientData,
  additionalInformation: AdditionalInformation,
  securityProfile: SecurityProfile,
  clientNumber: t.string,
  nuntioUrl: NonEmptyString,
  MiFID: t.type({ validTo: optionFromUndefined(DateFromSQLString) }),
  progress: t.type({
    total: fromNullable(t.number, 0),
    completed: fromNullable(t.number, 0),
  }),
});
export type PersonalProfile = t.TypeOf<typeof PersonalProfile>;

export const OtherDocumentType = t.keyof({
  PERMANENT_RESIDENCE_PERMIT: true,
  TEMPORARY_RESIDENCE_PERMIT: true,
  LONG_TERM_RESIDENCE_PERMIT: true,
  CONFIRMATION_FROM_MATRIKA: true,
  BIRTH_CERTIFICATE: true,
  SCANNED_ID_CARD: true,
});

export type OtherDocumentType = t.TypeOf<typeof OtherDocumentType>;

export const CFExistingErrorTypeKeys = t.union([
  CheckPrimaryIdEnumsCF,
  t.keyof({
    invalidAgeCF: true,
    foreignClientCF: true,
    hasBlockingNotesCF: true,
    highRiskClientCF: true,
    hasNotPasswordForCommunicationCF: true,
    privateClient: true,
    phoneNumberNotVerifiedCF: true,
    phoneNumberMissingCF: true,
  }),
]);

export const CFExistingErrorType = t.union([
  GenericError,
  t.type({ id: CFExistingErrorTypeKeys }),
]);

export type CFExistingErrorType = t.TypeOf<typeof CFExistingErrorType>;

export const NewUkontoErrorTypeKeys = t.union([
  CheckPrimaryIdEnums,
  t.keyof({
    invalidAge: true,
    hasBlockingNotes: true,
    highRiskClient: true,
    hasNotPasswordForCommunication: true,
    phoneNumberNotVerified: true,
    phoneNumberMissing: true,
    privateClient: true,
    hasSavingsAccount: true,
    hasCurrentAccounts: true,
    nonDigitalClient: true,
    nonDigitalClientSA: true,
  }),
]);

export const NewUkontoErrorType = t.union([
  GenericError,
  t.type({ id: NewUkontoErrorTypeKeys }),
]);

export type NewUkontoErrorType = t.TypeOf<typeof NewUkontoErrorType>;

export type PrimaryDocumentIssue =
  | "DifferentDocument"
  | "PrimaryDocumentExpired"
  | "PrimaryDocumentLostOrStolen"
  | "NoPrimaryDocument";

export type SecondaryDocumentIssue =
  | "SecondaryDocumentExpired"
  | "SecondaryDocumentLostOrStolen";

export type DocumentIssue = {
  documentPurpose: DocumentPurpose;
  issue: PrimaryDocumentIssue | SecondaryDocumentIssue;
};

export type EmptyLimitData = { preapprovedLimits: {} };
export type TlLimitData = {
  preapprovedLimits: {
    TL: Option<standardLoanSaleAPI.TLProductType>;
    CL: Option<standardLoanSaleAPI.CLProductType>;
  };
};
export type ClLimitData = {
  preapprovedLimits: {
    CL: Option<standardLoanSaleAPI.CLProductType>;
  };
};

export type LimitData = TlLimitData | ClLimitData | EmptyLimitData;

export function isTL(data: LimitData): data is TlLimitData {
  const match = data as TlLimitData;

  return (
    match.preapprovedLimits.TL &&
    pipe(
      match.preapprovedLimits.TL,
      option.fold(constFalse, TL => TL.producttype && TL.producttype === "TL")
    )
  );
}

export function isCL(data: LimitData): data is ClLimitData {
  const match = data as ClLimitData;

  return (
    match.preapprovedLimits.CL &&
    pipe(
      match.preapprovedLimits.CL,
      option.fold(constFalse, CL => CL.producttype && CL.producttype === "CL")
    )
  );
}

export function foldPreapprovedLimits<T>(matches: {
  whenEmpty: IO<T>;
  whenTl: Reader<TlLimitData, T>;
  whenCl: Reader<ClLimitData, T>;
}): Reader<LimitData, T> {
  return data => {
    if (isTL(data)) {
      return matches.whenTl(data);
    } else if (isCL(data)) {
      return matches.whenCl(data);
    } else {
      return matches.whenEmpty();
    }
  };
}

export function foldCLPreapprovedLimits<T>(matches: {
  whenNotCL: IO<T>;
  whenCl: Reader<ClLimitData, T>;
}): Reader<LimitData, T> {
  return data => {
    if (isCL(data)) {
      return matches.whenCl(data);
    } else {
      return matches.whenNotCL();
    }
  };
}

export const NewMortgageErrorTypeKeys = t.union([
  CheckPrimaryIdEnums,
  NewMortgageCheckClientStatus,
]);
export const NewMortgageErrorType = t.union([
  GenericError,
  t.type({ id: NewMortgageErrorTypeKeys }),
  t.type({ id: t.literal("phoneNumberNotVerified") }),
]);

export type NewMortgageErrorType = t.TypeOf<typeof NewMortgageErrorType>;
