import * as api from "./authorizationApi";
import { useCommand, useQuery } from "./useAPI";
import { constNull, pipe } from "fp-ts/function";
import * as remoteData from "./RemoteData";
import { AppProvider } from "./AppProvider";
import { AuthenticationContextProvider } from "./AuthenticationContext";
import { option, taskEither } from "fp-ts";
import { EnvBadge } from "./Common/EnvBadge/EnvBadge";
import { useState } from "react";
import { FeedbackDialog } from "design-system";
import { useFormatMessage } from "./intl";

type Props = React.ComponentProps<typeof AppProvider>;

function AnonymousAuth(props: { children: JSX.Element }) {
  const [authInfo] = useQuery(api.anonymousToken);
  const potentialClientToken = useCommand(api.potentialClientToken);

  const [showRefreshPageModal, setShowRefreshPageModal] = useState(false);
  const formatMessage = useFormatMessage();

  return pipe(
    authInfo,
    remoteData.fold(constNull, constNull, authInfo => (
      <AuthenticationContextProvider
        authInfo={option.some(authInfo)}
        potentialClientToken={pipe(
          potentialClientToken({ flowId: authInfo.flowId }),
          taskEither.map(option.some)
        )}
        onLogout={() => setShowRefreshPageModal(true)}
      >
        {props.children}
        <EnvBadge />

        {showRefreshPageModal && (
          <FeedbackDialog
            type="warning"
            title={formatMessage("AuthExpiredWarningDialog.title")}
            subtitle={formatMessage("AuthExpiredWarningDialog.subtitle")}
            cta={{
              label: formatMessage("AuthExpiredWarningDialog.cta"),
              action: () => window.location.reload(),
            }}
          />
        )}
      </AuthenticationContextProvider>
    ))
  );
}

export function AnonymousAuthProvider(props: Props) {
  return (
    <AppProvider {...props}>
      <AnonymousAuth>{props.children}</AnonymousAuth>
    </AppProvider>
  );
}
