import { Body, link, Stack, useIsMobileLayout } from "design-system";
import { useFormatMessage } from "../../intl";

export function HelpLineSupportDocumentId() {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  return (
    <Stack units={1} column>
      <Body
        size="small"
        weight="regular"
        align={isMobileLayout ? "center" : "left"}
      >
        {formatMessage("Helpline.support.scanning.id.document.description")}
      </Body>
      <Body
        size="small"
        weight="regular"
        align={isMobileLayout ? "center" : "left"}
      >
        {isMobileLayout
          ? [
              formatMessage("Helpline.support.scanning.id.document.suffix"),
              link(
                `tel:${formatMessage(
                  "Helpline.support.scanning.id.document.phoneNumber"
                ).replace(/\s/g, "")}`,
                formatMessage(
                  "Helpline.support.scanning.id.document.phoneNumber"
                )
              ),
            ]
          : [
              formatMessage("Helpline.support.scanning.id.document.suffix"),
              formatMessage(
                "Helpline.support.scanning.id.document.phoneNumber"
              ),
            ]}
      </Body>
    </Stack>
  );
}
